<template>
  <div class="peh-container">
    <div class="peh-title-container">
      <div class="app-container">
        <h3 class="peh-title">Riwayat Pendidikan</h3>
      </div>
    </div>

    <div class="app-container">
      <!-- RIWAYAT PENDIDIKAN -->
      <div class="peh-card-container">
        <div class="peh-subtitle-container">
          <h5 class="peh-subtitle">Riwayat Pendidikan</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(education_history, index) in educationHistories"
              :key="education_history.education_type + index"
            >
              <div class="mb-4">
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + education_history.education_type }}</h3>
              </div>

              <div class="mb-4">
                <base-label
                  :label="education_history.education_type === 'Universitas' ? 'Nama Universitas' : 'Nama Sekolah'"
                  :value="education_history.education_name"
                />
              </div>
              <div class="mb-4">
                <base-label
                  label="Kota"
                  :value="education_history.city"
                />
              </div>
              <div class="mb-4">
                <base-label
                  label="Tahun Kelulusan"
                  :value="String(education_history.graduation_year)"
                />
              </div>
              <div
                class="mb-4"
                v-if="education_history.major"
              >
                <base-label
                  label="Tahun Kelulusan"
                  :value="education_history.major"
                />
              </div>
              <div
                class="mb-4"
                v-if="education_history.paid_by"
              >
                <base-label
                  label="Dibiayai Oleh"
                  :value="education_history.paid_by"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- KURSUS PELATIHAN -->
      <div class="peh-card-container">
        <div class="peh-subtitle-container">
          <h5 class="peh-subtitle">Kursus/Pelatihan</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-lg-6"
              v-for="(course, index) in flk.courses"
              :key="course.course_name + index"
            >
              <div class="mb-3">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + course.course_name }}</h3>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="mb-4">
                    <base-label
                      label="Lama Kursus"
                      :value="course.course_periode + ' Bulan'"
                    />
                  </div>
                  <div class="mb-4">
                    <base-label
                      label="Mulai"
                      :value="dateFormat(course.start_date, 'dd mmmm yyyy')"
                    />
                  </div>
                  <div class="mb-4">
                    <base-label
                      label="Selesai"
                      :value="dateFormat(course.end_date, 'dd mmmm yyyy')"
                    />
                  </div>
                  <div class="mb-4">
                    <base-label
                      label="Penyelenggara"
                      :value="course.organizer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PRESTASI -->
      <div class="peh-card-container">
        <div class="peh-subtitle-container">
          <h5 class="peh-subtitle">Prestasi</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(achievement, index) in flk.achievements"
              :key="achievement.achievement_name + index"
            >
              <div>
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + achievement.achievement_name }}</h3>
              </div>
              <div class="ml-4">
                <p class="mb-0">Tahun {{ achievement.year }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BAHASA DIKUASAI -->
      <div class="peh-card-container">
        <div class="peh-subtitle-container">
          <h5 class="peh-subtitle">Bahasa Yang Dikuasai</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(language, index) in flk.languages"
              :key="language.language_name + index"
            >
              <div>
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + language.language_name }}</h3>
              </div>
              <div class="ml-4">
                <p class="mb-0">{{ language.is_active === true ? 'Aktif' : 'Pasif' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import useDateFormat from '@/composables/useDateFormat'

export default defineComponent({
  props: {
    flk: {
      type: Object
    }
  },
  setup(props) {
    const dateFormat = useDateFormat()

    const educationHistories = computed(() => {
      if (!props.flk.education_histories) {
        return []
      }

      const educationHistories = props.flk.education_histories
      return educationHistories.sort((a, b) => (a.graduation_year < b.graduation_year) ? 1 : ((b.graduation_year < a.graduation_year) ? -1 : 0))
    })

    return {
      dateFormat,
      educationHistories
    }
  }
})
</script>

<style lang="scss" scoped>
.peh-container {
  margin: 40px 0;

  .peh-title-container {
    background: #1659ab;
    padding: 15px 0;

    .peh-title {
      margin-bottom: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }
  }

  .peh-card-container {
    margin: 32px 0;

    .peh-subtitle-container {
      margin-bottom: 15px;

      .peh-subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>