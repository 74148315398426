<template>
  <div
    class="page-content"
    ref="pageContent"
  >
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>

    <div class="container-fluid mt--9">
      <generate-flk @onGenerated="onGeneratedFlk" />

      <div class="card shadow mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Daftar Pelamar</h3>
            <div class="ml-auto">
              <base-button
                type="danger"
                :disabled="deleteFlksPromised.state.loading"
                @click="deleteSelectedRow"
              >Hapus</base-button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <el-table
            ref="listFlksTable"
            :data="flk.data"
            style="width: 100%"
            v-loading="getFlksPromised.state.loading"
            @selection-change="selectTableRow"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              property="name"
              label="Nama Pendaftar"
              min-width="150"
            />
            <el-table-column
              property="job_position.job_vacancy_title"
              label="Posisi Pekerjaan"
              min-width="150"
            />
            <el-table-column
              property="id"
              label="Url Token"
              min-width="150"
              align="center"
            >
              <template #default="scope">
                <base-button
                  size="sm"
                  type="primary"
                  @click="openUrlGenerateToken(scope)"
                >Lihat Url</base-button>
              </template>
            </el-table-column>
            <el-table-column
              property="is_reviewed"
              label="Ditinjau"
              align="center"
              min-width="150"
            >
              <template #default="scope">
                <el-switch
                  v-model="scope.row.is_reviewed"
                  :loading="scope.row.review_loading"
                  @click="reviewUnreviewFlk(scope)"
                >
                </el-switch>
              </template>
            </el-table-column>

            <el-table-column
              label="Aksi"
              width="250"
              align="center"
            >
              <template #default="scope">
                <el-button
                  v-if="scope.row.is_complete === true"
                  @click="goToDetailPage(scope)"
                  type="primary"
                  size="small"
                  icon="el-icon-view"
                  round
                ></el-button>
                <el-button
                  v-if="scope.row.is_complete === true"
                  @click="exportPdf(scope)"
                  type="primary"
                  size="small"
                  icon="el-icon-upload"
                  round
                ></el-button>
                <el-button
                  @click="goToEditPage(scope)"
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  round
                  @click="selectTableRow([{ ...scope.row }], true)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="text-center my-2">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="flk.total"
              :page-size="flk.per_page"
              @current-change="changePageTable"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue"
import { useMeta } from "vue-meta"
import { useRoute, useRouter } from "vue-router"
import { StatusCodes } from "http-status-codes"
import usePageMeta from "@/composables/usePageMeta"
import useSwal from "@/composables/useSwal"
import useAxios from "@/composables/useAxios"

import GenerateFlk from '@/components/Flk/GenerateToken.vue'

import flkService from '@/services/Flk'

export default defineComponent({
  components: {
    GenerateFlk
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    useMeta(usePageMeta(route).pageMeta)

    const state = reactive({
      flk: {
        data: [],
        total: 0,
        per_page: 10,
        page: 1,
        selectedId: []
      },
    })

    // TABLE ACTION
    const goToDetailPage = (scope) => {
      router.push({
        name: 'Detail Flk',
        params: {
          id: scope.row.id
        }
      })
    }
    const goToPrintPage = (scope) => {
      const routeData = router.resolve({
        name: 'Print Flk',
        params: {
          id: scope.row.id
        }
      });
      window.open(routeData.href, '_blank');
    }
    const goToEditPage = (scope) => {
      router.push({
        name: 'Ubah Flk',
        params: {
          id: scope.row.id
        }
      })
    }

    const listFlksTable = ref()

    const selectTableRow = async (val, deleteImmediately = false) => {
      if (deleteImmediately === true) {
        listFlksTable.value.clearSelection()
      }

      state.flk.selectedId = val.map((value) => value.id)

      if (deleteImmediately === true) {
        await deleteSelectedRow()
        state.category.selectedId = []
      }
    }

    const changePageTable = async (currentPage) => {
      state.flk.page = currentPage
      await getFlks()
    }

    const openUrlGenerateToken = (scope) => {
      Swal.fire({
        title: 'Token Url',
        html: `<a href="${scope.row.url_token}" target="_blank">${scope.row.url_token}</a>`,
      })
    }

    // GET SECTION
    const getFlksPromised = useAxios((payload = {}) => {
      return flkService.superadmin.getFlks(payload)
    })

    const getFlks = async () => {
      await getFlksPromised.createPromise({
        params: {
          paginate: true,
          per_page: state.flk.per_page,
          page: state.flk.page
        }
      })

      if (getFlksPromised.state.response && getFlksPromised.state.statusCode === StatusCodes.OK) {
        const data = getFlksPromised.state.data
        const flk = data.data.data.map(flk => {
          return {
            ...flk,
            review_loading: false
          }
        })
        Object.assign(state.flk, {
          data: flk,
          total: data.data.total,
          per_page: Number(data.data.per_page),
          page: data.data.current_page
        })
      } else if (getFlksPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Failed to get flks',
          text: getFlksPromised.state.errorMessage,
        })
      }
    }

    // GENERATE
    const onGeneratedFlk = async () => {
      state.flk.page = 1
      await getFlks()
    }

    // REVIEWED
    const reviewFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.reviewFlk(id, payload)
    }, {
      withIndicator: true
    })

    const unreviewFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.unreviewFlk(id, payload)
    }, {
      withIndicator: true
    })

    const reviewUnreviewFlk = async (scope) => {
      if (scope.row.is_reviewed) {
        state.flk.data[scope.$index].review_loading = true
        await reviewFlkPromised.createPromise(scope.row.id)
        state.flk.data[scope.$index].review_loading = false

        if (reviewFlkPromised.state.response) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'success',
            title: 'Status flk berhasil diubah menjadi ditinjau',
          })
        } else if (reviewFlkPromised.state.error) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'error',
            title: 'Status flk gagal diubah menjadi ditinjau',
          })

          state.flk.data[scope.$index].is_reviewed = !state.flk.data[scope.$index].is_reviewed
        }
      } else {
        state.flk.data[scope.$index].review_loading = true
        await unreviewFlkPromised.createPromise(scope.row.id)
        state.flk.data[scope.$index].review_loading = false

        if (unreviewFlkPromised.state.response) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'success',
            title: 'Status flk berhasil diubah menjadi tidak ditinjau',
          })
        } else if (unreviewFlkPromised.state.error) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'error',
            title: 'Status flk gagal diubah menjadi tidak ditinjau',
          })

          state.flk.data[scope.$index].is_reviewed = !state.flk.data[scope.$index].is_reviewed
        }
      }
    }

    // EXPORT
    const exportPdfPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.exportFlk(id, payload)
    }, {
      withIndicator: true
    })
    const exportPdf = async (scope) => {
      await exportPdfPromised.createPromise(scope.row.id)

      if (exportPdfPromised.state.response) {
        const url = window.URL.createObjectURL(new Blob([exportPdfPromised.state.response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `FLK - ${scope.row.name} - ${scope.row.job_position.job_vacancy_title}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else if (exportPdfPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal men-ekspor flk',
          text: exportPdfPromised.state.errorMessage,
        })
      }
    }

    // DELETE SECTION
    const deleteSelectedRow = async () => {
      if (!state.flk.selectedId.length) {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 1500,
          icon: 'info',
          title: 'Silakan pilih flk yang akan dihapus',
        })
        return
      }

      const continueDelete = await new Promise((resolve, reject) => {
        Swal.fire({
          icon: "warning",
          title: "Apa kamu yakin?",
          text: "Flk yang dihapus tidak dapat dikembalikan",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Hapus",
          cancelButtonText: "Batalkan"
        }).then(result => {
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })

      if (continueDelete.value) {
        await deleteFlks()
        if (
          deleteFlksPromised.state.response &&
          getFlksPromised.state.statusCode === StatusCodes.OK
        ) {
          state.flk.selectedId = []
          await getFlks()
        }
      }
    }

    const deleteFlksPromised = useAxios((payload = {}) => {
      return flkService.superadmin.deleteFlks(payload)
    }, {
      withIndicator: true
    })
    const deleteFlks = async () => {
      await deleteFlksPromised.createPromise({
        data: {
          id: state.flk.selectedId
        }
      })

      if (deleteFlksPromised.state.response && getFlksPromised.state.statusCode === StatusCodes.OK) {
        Swal.fire({
          icon: 'success',
          title: 'Flk berhasil dihapus',
        })
      } else if (deleteFlksPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal menghapus flk',
          text: getFlksPromised.state.errorMessage,
        })
      }
    }

    onMounted(async () => {
      await getFlks()
    })

    return {
      ...toRefs(state),
      listFlksTable,
      goToDetailPage,
      goToPrintPage,
      goToEditPage,
      selectTableRow,
      deleteSelectedRow,
      changePageTable,
      openUrlGenerateToken,
      getFlksPromised,
      getFlks,
      onGeneratedFlk,
      reviewUnreviewFlk,
      exportPdf,
      deleteFlksPromised,
      deleteFlks
    };
  },
})
</script>
