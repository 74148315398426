<template>
  <div class="po-container">
    <div class="po-title-container">
      <div class="app-container">
        <h3 class="po-title">Informasi Pekerjaan dan Organisasi</h3>
      </div>
    </div>

    <div class="app-container">
      <!-- RIWAYAT PENDIDIKAN -->
      <div class="po-card-container">
        <div class="po-subtitle-container">
          <h5 class="po-subtitle">Riwayat Pekerjaan</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(jobHistory, index) in jobHistories"
              :key="jobHistory.company_name + index"
            >
              <div class="mb-3">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + jobHistory.company_name }}</h3>
              </div>

              <div class="mb-3">
                <base-label
                  label="Alamat"
                  :value="jobHistory.address"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Masa Kerja"
                  :value="jobHistory.start_year + ' - ' + jobHistory.end_year"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Gaji Terakhir"
                  :value="formatRupiah(jobHistory.last_salary, 'Rp ')"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Posisi Terakhir"
                  :value="jobHistory.position"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Alasan Keluar"
                  :value="jobHistory.reason_of_resigning"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- RIWAYAT ORGANISASI -->
      <div class="po-card-container">
        <div class="po-subtitle-container">
          <h5 class="po-subtitle">Riwayat Organisasi</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(organization, index) in flk.organizations"
              :key="organization.organization_name + index"
            >
              <div>
                <h3 class="mb-3">{{ String.fromCharCode(index + 65) + '. ' + organization.organization_name }}</h3>
              </div>
              <div class="mb-3">
                <base-label
                  label="Jabatan"
                  :value="organization.position"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Keterangan"
                  :value="organization.note"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { formatRupiah } from '@/composables/useStringFormat'

export default defineComponent({
  props: {
    flk: {
      type: Object
    }
  },
  setup(props) {
    const jobHistories = computed(() => {
      if (!props.flk.job_histories) {
        return []
      }

      const jobHistories = props.flk.job_histories
      return jobHistories.sort((a, b) => (a.end_year < b.end_year) ? 1 : ((b.end_year < a.end_year) ? -1 : 0))
    })

    return {
      formatRupiah,
      jobHistories
    }
  }
})
</script>

<style lang="scss">
.po-container {
  margin: 40px 0;

  .po-title-container {
    background: #1659ab;
    padding: 15px 0;

    .po-title {
      margin-bottom: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }
  }

  .po-card-container {
    margin: 32px 0;

    .po-subtitle-container {
      margin-bottom: 15px;

      .po-subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>