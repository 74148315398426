export const formatRupiah = (number, prefix = undefined) => {
  const number_string = number ? number.toString().replace(/[^,\d]/g, "") : '',
    split = number_string.split(","),
    sisa = Number(split[0]).toString().length % 3,
    ribuan = Number(split[0])
      .toString()
      .substr(sisa)
      .match(/\d{3}/gi)

  let rupiah = Number(split[0])
    .toString()
    .substr(0, sisa),
    separator = ""

  if (ribuan) {
    separator = sisa ? "." : ""
    rupiah += separator + ribuan.join(".")
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah
  return prefix == undefined ? rupiah : (rupiah ? (prefix + rupiah) : "")
}

export const genRandomStr = (length = 8) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const formatRupiahToInteger = (strFormatRupiah, prefix = '') => {
  // eslint-disable-next-line no-useless-escape
  return Number(strFormatRupiah.replace(prefix, '').replace(/[\.]+/g, ''))
}

export const onlyNumber = (evt) => {
  evt = (evt) ? evt : window.event;
  const charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault()
  } else {
    return true;
  }
}

export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const sortArrayObject = (obj, property) => {
  return obj.sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0))
}