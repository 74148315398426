<template>
  <div class="it-container">
    <img
      :src="imgUrl"
      :alt="altName"
      class="img-thumbnail"
    >
    <a
      target="_blank"
      :href="imgUrl"
      class="btn btn-img-download"
    >Download</a>
    <div class="it-blur"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    altName: {
      type: String,
      default: ''
    }
  },
  setup() {
    // 
  }
})
</script>

<style lang="scss" scoped>
.it-container {
  position: relative;

  .it-blur {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .it-blur:hover {
    background: #696868;
    opacity: 0.5;
  }

  .btn.btn-img-download {
    background: #ffffff;
    border: 1px solid #1659ab;
    box-sizing: border-box;
    border-radius: 40px;
    color: #1659ab;
    padding: 8px 27px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-55%, -135%);
    z-index: 2;
  }
  .img-thumbnail {
    object-fit: contain;
    max-height: 300px;
    width: 100%;
  }
}
</style>