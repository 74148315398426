<template>
  <div class="pf-header">
    <div class="app-container">
      <div class="row">
        <div class="col-md-10">
          <div class="pf-paninti-logo-container">
            <img
              class="img-fluid"
              src="/img/brand/paninti-logo-white.svg"
              alt="Paninti Logo"
            >
          </div>
          <div class="pf-dev-address-container">
            <p class="pf-dev-address">{{ devAddress }}</p>
          </div>
          <div class="pf-social-media-container">
            <a
              class="pf-social-media-link"
              target="_blank"
              :href="devYoutubeUrl"
              media="print"
            >
              <social-icon1
                size="32"
                iconUrl="/img/icons/youtube-icon.svg"
                label="Paninti Duta Internusa"
              />
            </a>
            <a
              class="pf-social-media-link"
              target="_blank"
              :href="devLinkedInUrl"
            >
              <social-icon1
                size="32"
                iconUrl="/img/icons/linkedin-icon.svg"
                label="Paninti Duta Internusa"
              />
            </a>
            <a
              class="pf-social-media-link"
              target="_blank"
              :href="devIntagramUrl"
            >
              <social-icon1
                size="32"
                iconUrl="/img/icons/instagram-icon.svg"
                label="Paninti Duta Internusa"
              />
            </a>
          </div>
        </div>
        <div class="col-md-2">
          <div class="pf-photo-profile-container">
            <img
              v-if="flk.medias.length > 0"
              class="pf-photo-profile"
              :src="flk.medias[0].url"
              alt="Photo Profile"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    flk: {
      type: Object,
    }
  },

  setup() {
    const store = useStore()

    return {
      devAddress: computed(() => store.getters['config/devAddress']),
      devFacebookUrl: computed(() => store.getters['config/devFacebookUrl']),
      devIntagramUrl: computed(() => store.getters['config/devIntagramUrl']),
      devLinkedInUrl: computed(() => store.getters['config/devLinkedInUrl']),
      devYoutubeUrl: computed(() => store.getters['config/devYoutubeUrl']),
    }
  }
})
</script>

<style lang="scss" scoped>
.pf-header {
  padding: 40px 0;
  background-color: #1659ab;
  color: #fff;

  .pf-paninti-logo-container {
    max-width: 225px;
  }

  .pf-dev-address-container {
    margin-top: 15px;
    margin-bottom: 6px;

    .pf-dev-address {
      margin-bottom: 0;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .pf-social-media-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .pf-social-media-link {
      margin: 10px 0;
      color: #fff;
      margin-right: 30px;
    }
  }

  .pf-photo-profile-container {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .pf-photo-profile {
      width: 100%;
      max-height: 290px;
      object-fit: contain;
    }
  }
}

@media (max-width: 767px) {
  .pf-header {
    .pf-photo-profile-container {
      position: relative;
    }
  }
}

@media print {
  a {
    text-decoration: none !important;
  }
}
</style>
