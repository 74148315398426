export const firstFamilyType = 'Istri/Suami dan Anak-anak'
export const secondFamilyType = 'Ayah, Ibu, dan Saudara sekandung, termasuk Saudara'

export const dataInputTypeName = {
  liveWithInput: 'live_with_input',
  maritalStatusInput: 'marital_status_input',
  familyDataInput: 'family_data_input',
  educationHistoryInput: 'education_history_input',
  jobHistoryInput: 'job_history_input',
  interestAndTalentInput: 'interest_and_talent_input',
  socialActivityInput: 'social_activity_input'
}

export const staticInputValue = {
  religions: [
    {
      label: 'Islam',
      value: 'Islam'
    },
    {
      label: 'Kristen',
      value: 'Kristen'
    },
    {
      label: 'Katolik',
      value: 'Katolik'
    },
    {
      label: 'Hindu',
      value: 'Hindu'
    },
    {
      label: 'Budha',
      value: 'Budha'
    },
    {
      label: 'Konghucu',
      value: 'Konghucu'
    },
    {
      label: 'Lainnya',
      value: 'Lainnya'
    }
  ],
  genders: [
    {
      label: 'Laki-laki',
      value: 'L'
    },
    {
      label: 'Perempuan',
      value: 'P'
    },
  ],
  bloodTypes: [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'AB',
      value: 'AB',
    },
    {
      label: 'O',
      value: 'O',
    },
  ],
  nationalities: [
    {
      label: 'WNI',
      value: 'WNI'
    },
    {
      label: 'WNA',
      value: 'WNA'
    }
  ],
  liveWiths: [
    {
      label: 'Sendiri',
      value: 'Sendiri'
    },
    {
      label: 'Orang Tua',
      value: 'Orang Tua'
    },
    {
      label: 'Keluarga suami/istri',
      value: 'Keluarga suami/istri'
    },
    {
      label: 'Kost',
      value: 'Kost'
    },
    {
      label: 'Kontrak',
      value: 'Kontrak'
    },
    {
      label: 'Lainnya',
      value: 'Lainnya'
    }
  ],
  maritalStatuses: [
    {
      label: 'Single',
      value: 'Single'
    },
    {
      label: 'Tunangan',
      value: 'Bertunangan'
    },
    {
      label: 'Menikah',
      value: 'Menikah'
    },
    {
      label: 'Duda',
      value: 'Duda'
    },
    {
      label: 'Janda',
      value: 'Janda'
    }
  ],
  educationLevels: [
    {
      label: 'SD',
      value: 'SD'
    },
    {
      label: 'SMP / SLTP',
      value: 'SMP/SLTP'
    },
    {
      label: 'SMA / SMK / SLTA',
      value: 'SMA/SMK/SLTA'
    },
    {
      label: 'D1 - D3',
      value: 'D1-D3'
    },
    {
      label: 'S1 / D4',
      value: 'S1/D4'
    },
    {
      label: 'S2 - S3',
      value: 'S2-S3'
    },
  ],
  languageProficiencies: [
    {
      label: 'Baik',
      value: 'Baik'
    },
    {
      label: 'Sedang',
      value: 'Sedang'
    },
    {
      label: 'Kurang',
      value: 'Kurang'
    }
  ]
}