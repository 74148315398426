import dateFormat from 'dateformat'

dateFormat.i18n = {
  dayNames: [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Juma'at",
    "Sabtu",
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Juma'at",
    "Sabtu",
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agust",
    "Sep",
    "Okt",
    "Nov",
    "Des",
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
}

export default function useDateFormat() {
  return dateFormat
}