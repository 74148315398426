<template>
  <div class="paj-container">
    <div class="app-container">
      <div class="paj-title-container">
        <h3 class="paj-title">Formulir Lamaran Kerja</h3>
      </div>

      <div class="pf-card">
        <div class="row">
          <div class="col-md-4">
            <base-label
              label="Posisi yang Diinginkan"
              :value="flk.job_position.job_vacancy_title"
            />
          </div>
          <div class="col-md-4">
            <base-label
              label="Pendidikan Terakhir"
              :value="flk.job_position.last_education"
            />
          </div>
          <div class="col-md-4">
            <base-label
              label="Gaji yang Diinginkan"
              :value="formatRupiah(flk.job_position.expected_salary, 'Rp ')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { formatRupiah } from '@/composables/useStringFormat'

export default defineComponent({
  props: {
    flk: {
      type: Object,
    }
  },
  setup() {
    return {
      formatRupiah
    }
  }
})
</script>

<style lang="scss">
.paj-container {
  .paj-title-container {
    text-align: center;
    margin: 50px 0;

    .paj-title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }
}
</style>