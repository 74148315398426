<template>
  <div class="card shadow">
    <div class="card-header bg-transparent">
      <h3 class="mb-0">Pembuatan Token</h3>
    </div>
    <div class="card-body">
      <el-form
        ref="formGenerateTokenRef"
        class="row form-generate-token"
        :inline="true"
        :model="formGenerateToken"
        :rules="formGenerateTokenRules"
        @submit.prevent="submitFormGenerateToken"
      >
        <el-form-item
          label="Nama"
          prop="name"
          class="col-lg-4"
        >
          <el-input
            placeholder="Silakan masukkan nama pelamar"
            v-model="formGenerateToken.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Posisi Pekerjaan"
          prop="job_vacancy_id"
          class="col-lg-5"
        >
          <el-select
            class="w-100"
            v-model="formGenerateToken.job_vacancy_id"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="Silakan masukkan judul pekerjaan"
            :remote-method="searchJobVacancies"
            :loading="searchJobVacanciesPromised.state.loading"
          >
            <el-option
              v-for="jobVacancy in jobVacancies"
              :key="jobVacancy.id"
              :label="jobVacancy.title"
              :value="jobVacancy.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="col-lg-3">
          <base-button
            class="mb-2"
            native-type="submit"
            type="primary"
          >Hasilkan</base-button>
        </el-form-item>
      </el-form>

      <div>
        <base-alert
          v-if="generateTokenResult.requested === true && generateTokenResult.error === false"
          type="success"
          class="mt-3"
        >
          <div>Token flk berhasil dibuat</div>
          <div>
            Url Token: {{ generateTokenResult.data.url_token }}
            <el-tooltip
              content="Salin"
              placement="top"
              effect="light"
            >
              <span
                @click="copyTokenUrl(generateTokenResult.data.url_token)"
                class="copy-icon-generate-token"
              ><i class="ni ni-single-copy-04"></i></span>
            </el-tooltip>
          </div>
        </base-alert>
        <base-alert
          v-if="generateTokenResult.requested === true && generateTokenResult.error === true"
          type="danger"
          class="mt-3"
        >
          Gagal menghasilkan token flk
        </base-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from "vue"
import useAxios from "@/composables/useAxios"
import useSwal from "@/composables/useSwal"
import { useClipboard } from '@vueuse/core'

import jobVacancyService from '@/services/JobVacancy'
import FlkService from '@/services/Flk'

export default defineComponent({
  emits: ['onGenerated'],
  setup(_props, { emit }) {
    const Swal = useSwal()

    const source = ref('')
    const { text, copy, copied } = useClipboard({ source })

    const state = reactive({
      formGenerateToken: {
        name: "",
        job_vacancy_id: ""
      },
      formGenerateTokenRules: {
        name: [
          { required: true, message: "Silakan masukkan nama pelamar", trigger: ['change', 'blur'] },
        ],
        job_vacancy_id: [
          { required: true, message: "Silakan masukkan judul pekerjaan", trigger: ['change', 'blur'] },
        ]
      },
      generateTokenResult: {
        requested: false,
        error: false,
        data: {}
      },
      jobVacancies: []
    })

    const copyTokenUrl = async (textCopy) => {
      await copy(textCopy)

      if (copied && text.value == textCopy) {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
          imageHeight: 50,
          title: 'Token Flk berhasil disalin',
        })
      } else {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 1500,
          icon: 'warning',
          imageHeight: 50,
          title: 'Token Flk gagal disalin',
        })
      }
    }

    const formGenerateTokenRef = ref()

    const searchJobVacanciesPromised = useAxios((payload = {}) => {
      return jobVacancyService.superadmin.searchJobVacancy(payload)
    })

    const searchJobVacancies = async (query) => {
      await searchJobVacanciesPromised.createPromise({
        params: {
          keyword: query,
        }
      })

      if (searchJobVacanciesPromised.state.response) {
        state.jobVacancies = searchJobVacanciesPromised.state.data.data
      } else if (searchJobVacanciesPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal mendapatkan lowongan kerja',
          text: searchJobVacanciesPromised.state.errorMessage,
        })
      }
    }

    const generateFlkTokenPromised = useAxios((payload) => {
      return FlkService.superadmin.generateToken(payload)
    }, {
      withIndicator: true
    })

    const submitFormGenerateToken = async () => {
      formGenerateTokenRef.value.validate(async (valid) => {
        if (!valid) {
          return
        }

        await generateFlkTokenPromised.createPromise({
          data: state.formGenerateToken
        })

        state.generateTokenResult.requested = true

        if (generateFlkTokenPromised.state.response) {
          state.generateTokenResult.error = false
          state.generateTokenResult.data = generateFlkTokenPromised.state.data

          emit('onGenerated')
        } else if (generateFlkTokenPromised.state.error) {
          state.generateTokenResult.error = true
        }
      })
    }

    return {
      ...toRefs(state),
      copyTokenUrl,
      searchJobVacanciesPromised,
      searchJobVacancies,
      formGenerateTokenRef,
      generateFlkTokenPromised,
      submitFormGenerateToken
    }
  },
})
</script>

<style lang="scss">
.form-generate-token {
  align-items: center;

  .el-form-item {
    margin-bottom: 0 !important;
    margin-right: 0 !important;

    .el-form-item__label {
      padding: 0 12px 0 0 !important;
    }
  }
}

.copy-icon-generate-token {
  cursor: pointer;
  margin-left: 5px !important;
}
</style>