<template>
  <div class="ppd-container">
    <div class="ppd-title-container">
      <div class="app-container">
        <h3 class="ppd-title">Data Diri</h3>
      </div>
    </div>

    <div class="app-container">
      <!-- DATA DIRI -->
      <div class="ppd-card-container">
        <div class="ppd-subtitle-container">
          <h5 class="ppd-subtitle">Data Diri</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div class="col-md-4 mb-4">
              <base-label
                label="Nama Lengkap"
                :value="flk.name"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Alamat"
                :value="flk.address"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Agama"
                :value="flk.religion"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Tempat, Tanggal Lahir"
                :value="flk.place_of_birth + ', ' + flk.date_of_birth"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="No. KTP"
                :value="flk.ktp_number"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Golongan Darah"
                :value="flk.blood_type"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="No Telepon"
                :value="flk.phone"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Kewarganegaraan"
                :value="flk.nationality"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Tinggal Dengan"
                :value="flk.live_with"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Email"
                :value="flk.email"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Jenis Kelamin"
                :value="flk.gender"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Status Perkawinan"
                :value="flk.marital_status"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Hobi"
                :value="flk.hobbies"
              />
            </div>
            <div class="col-md-4 mb-4">
              <base-label
                label="Bersedia Ditempatkan diluar Kota"
                :value="flk.is_ready_out_of_city === true ? 'Bersedia' : 'Tidak Bersedia'"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- SUSUNAN KELUARGA -->
      <div class="ppd-card-container">
        <div class="ppd-subtitle-container">
          <h5 class="ppd-subtitle">Sususan Keluarga</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(family, index) in flk.families"
              :key="family.relationship_status"
            >
              <div class="mb-4">
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + family.relationship_status }}</h3>
              </div>

              <div class="mb-4">
                <base-label
                  label="Nama Lengkap"
                  :value="flk.name"
                />
              </div>
              <div class="mb-4">
                <base-label
                  label="Tempat, Tanggal Lahir"
                  :value="family.place_of_birth + ', ' + family.date_of_birth"
                />
              </div>
              <div class="mb-4">
                <base-label
                  label="Pendidikan Terakhir"
                  :value="family.last_education"
                />
              </div>
              <div class="mb-4">
                <base-label
                  label="Pekerjaan"
                  :value="family.job"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MEDIA SOSIAL -->
      <div class="ppd-card-container">
        <div class="ppd-subtitle-container">
          <h5 class="ppd-subtitle">Media Sosial</h5>
        </div>

        <div class="pf-card">
          <div class="row">
            <div
              class="col-md-4"
              v-for="socmed in flk.socmeds"
              :key="socmed.name"
            >
              <base-label
                :label="socmed.name"
                :value="socmed.username"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    flk: {
      type: Object
    }
  },
  setup() {
    // 
  }
})
</script>

<style lang="scss" scoped>
.ppd-container {
  margin: 40px 0;
  .ppd-title-container {
    background: #1659ab;
    padding: 15px 0;

    .ppd-title {
      margin-bottom: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }
  }

  .ppd-card-container {
    margin: 32px 0;

    .ppd-subtitle-container {
      margin-bottom: 15px;

      .ppd-subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>