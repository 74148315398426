<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    >
    </base-header>
    <div
      class="container-fluid mt--9"
      v-if="formEditFlk.token"
    >
      <el-form
        label-position="top"
        label-width="100px"
        ref="formEditFlkRef"
        :model="formEditFlk"
        @submit.prevent="updateFlk"
      >
        <div class="card shadow">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <el-form-item
                  label="Posisi Yang Diinginkan"
                  prop="job_position.job_vacancy_title"
                >
                  <el-input
                    disabled
                    v-model="formEditFlk.job_position.job_vacancy_title"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-lg-4">
                <el-form-item
                  label="Pendidikan Terakhir"
                  prop="job_position.last_education"
                  :rules="[{ required: true, message: 'Tolong masukan pendidikan terakhir anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.job_position.last_education"
                    placeholder=" "
                  >
                    <el-option
                      v-for="educationLevel in educationLevels"
                      :key="educationLevel.value"
                      :label="educationLevel.label"
                      :value="educationLevel.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-lg-4">
                <el-form-item
                  label="Gaji Yang Diinginkan"
                  prop="job_position.expected_salary"
                  :rules="[{ required: true, message: 'Tolong masukan gaji yang anda inginkan', trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    @input="formEditFlk.job_position.expected_salary = formatRupiah(formEditFlk.job_position.expected_salary, 'Rp. ')"
                    v-model="formEditFlk.job_position.expected_salary"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- DATA DIRI -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Data Diri</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                <el-upload
                  class="avatar-uploader"
                  name="files[0]"
                  accept="image/png, image/jpeg"
                  :action="uploadProfilePhoto.apiUrl"
                  :show-file-list="false"
                  :on-success="uploadPPSuccess"
                  :headers="uploadProfilePhoto.headers"
                >
                  <img
                    v-if="uploadProfilePhoto.fileUrl"
                    :src="uploadProfilePhoto.fileUrl"
                    class="avatar-img"
                  />
                  <i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                  ></i>
                </el-upload>
              </div>
              <div class="col-xl-4">
                <el-form-item
                  label="Nama Lengkap"
                  prop="data.name"
                  :rules="[{ required: true, message: 'Tolong masukan nama anda', trigger: ['change', 'blur'] }]"
                >
                  <el-input v-model="formEditFlk.data.name"></el-input>
                </el-form-item>

                <div class="row">
                  <div class="col-lg-6">
                    <el-form-item
                      label="Tempat Lahir"
                      prop="data.place_of_birth"
                      :rules="[{ required: true, message: 'Tolong masukan tempat lahir anda', trigger: ['change', 'blur'] }]"
                    >
                      <el-input v-model="formEditFlk.data.place_of_birth"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-lg-6">
                    <el-form-item
                      label="Tanggal Lahir"
                      prop="data.date_of_birth"
                      :rules="[{ required: true, message: 'Tolong masukan tanggal lahir anda', trigger: ['change', 'blur'] }]"
                    >
                      <el-date-picker
                        class="w-100"
                        v-model="formEditFlk.data.date_of_birth"
                        type="date"
                        placeholder=""
                        :disabled-date="disableDateAfterNow"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item
                  label="Nomor Telepon"
                  prop="data.phone"
                  :rules="[{ required: true, message: 'Tolong masukan nomor telepon anda', trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    @keypress="onlyNumber($event)"
                    v-model="formEditFlk.data.phone"
                  />
                </el-form-item>
                <el-form-item
                  label="Email"
                  prop="data.email"
                  :rules="[
                    { required: true, message: 'Tolong masukan email anda', trigger: ['change', 'blur'] },
                    { type: 'email', message: 'Tolong masukan email dengan benar', trigger: ['blur', 'change'] }
                  ]"
                >
                  <el-input v-model="formEditFlk.data.email"></el-input>
                </el-form-item>
                <el-form-item
                  label="Alamat"
                  prop="data.address"
                  :rules="[{ required: true, message: 'Tolong masukan alamat anda', trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    placeholder=""
                    v-model="formEditFlk.data.address"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="Agama"
                  prop="data.religion"
                  :rules="[{ required: true, message: 'Tolong masukan agama anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.religion"
                    placeholder=""
                  >
                    <el-option
                      v-for="religion in religions"
                      :key="'rs-' + religion.value"
                      :label="religion.label"
                      :value="religion.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="No. Ktp"
                  prop="data.ktp_number"
                  :rules="[{ required: true, message: 'Tolong masukan nomor ktp anda', trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    @keypress="onlyNumber($event)"
                    v-model="formEditFlk.data.ktp_number"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="KTP Dikeluarkan Di"
                  prop="data.ktp_published_at"
                  :rules="[{ required: true, message: 'Tolong masukan tempat ktp anda dibuat', trigger: ['change', 'blur'] }]"
                >
                  <el-input v-model="formEditFlk.data.ktp_published_at"></el-input>
                </el-form-item>

                <div class="row">
                  <div class="col-lg-6">
                    <el-form-item
                      label="Berlaku Dari"
                      prop="data.ktp_valid_from"
                    >
                      <el-date-picker
                        v-model="formEditFlk.data.ktp_valid_from"
                        type="date"
                        class="w-100"
                        format="DD-MM-YYYY"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disableDateAfterNow"
                        @change="handleChangeKTPValidDateStart"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="col-lg-6">
                    <el-form-item
                      label="Berlaku Sampai"
                      prop="data.ktp_valid_until"
                    >
                      <el-date-picker
                        v-model="formEditFlk.data.ktp_valid_until"
                        type="date"
                        class="w-100"
                        format="DD-MM-YYYY"
                        value-format="YYYY-MM-DD"
                        :disabled-date="(...args) => disabledByDateStart(...args, formEditFlk.data.ktp_valid_from)"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item
                  label="S.I.M Nomor"
                  prop="data.sim_number"
                >
                  <el-input
                    @keypress="onlyNumber($event)"
                    v-model="formEditFlk.data.sim_number"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-xl-4">
                <el-form-item
                  label="Tanggal S.I.M Dikeluarkan"
                  prop="data.sim_published_at"
                >
                  <el-date-picker
                    v-model="formEditFlk.data.sim_published_at"
                    type="date"
                    class="w-100"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disableDateAfterNow"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Golongan Darah"
                  prop="data.blood_type"
                  :rules="[{ required: true, message: 'Tolong masukan golongan darah anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.blood_type"
                    placeholder=""
                  >
                    <el-option
                      v-for="(bloodType) in bloodTypes"
                      :key="'bts-' + bloodType.label"
                      :label="bloodType.label"
                      :value="bloodType.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Kewarganegaraan"
                  prop="data.nationality"
                  :rules="[{ required: true, message: 'Tolong masukan kewarganegaraan anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.nationality"
                    placeholder=""
                  >
                    <el-option
                      v-for="(nationality) in nationalities"
                      :key="'ns-' + nationality.label"
                      :label="nationality.label"
                      :value="nationality.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Jenis Kelamin"
                  prop="data.gender"
                  :rules="[{ required: true, message: 'Tolong masukan jenis kelamin anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.gender"
                    placeholder=""
                  >
                    <el-option
                      v-for="(gender) in genders"
                      :key="'gs-' + gender.label"
                      :label="gender.label"
                      :value="gender.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Tinggal Dengan"
                  prop="data.live_with"
                  :rules="[{ required: true, message: 'Tolong masukan tinggal dengan anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.live_with"
                    placeholder=""
                  >
                    <el-option
                      v-for="(liveWith) in liveWiths"
                      :key="'lws-' + liveWith.label"
                      :label="liveWith.label"
                      :value="liveWith.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="liveWithQuestion.data"
                  :label="liveWithQuestion.data.question"
                  :prop="'another_questions.' + liveWithQuestion.index + '.answer'"
                  :rules="[{ required: true, message: liveWithQuestion.validationMessages.required, trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    v-model="formEditFlk.another_questions[liveWithQuestion.index].answer"
                    :placeholder="liveWithQuestion.placeholder"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Status Perkawinan"
                  prop="data.marital_status"
                  :rules="[{ required: true, message: 'Tolong masukan status perkawinan anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.marital_status"
                    placeholder=""
                    @change="handleChangeMaritalStatus"
                  >
                    <el-option
                      v-for="(maritalStatus) in maritalStatuses"
                      :key="'mss-' + maritalStatus.label"
                      :label="maritalStatus.label"
                      :value="maritalStatus.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="maritalStatusQuestion.data"
                  :label="maritalStatusQuestion.data.question"
                  :prop="'another_questions.' + maritalStatusQuestion.index + '.answer'"
                  :rules="[{ required: true, message: maritalStatusQuestion.validationMessages.required, trigger: ['change', 'blur'] }]"
                >
                  <el-date-picker
                    v-model="formEditFlk.another_questions[maritalStatusQuestion.index].answer"
                    type="date"
                    class="w-100"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    :placeholder="maritalStatusQuestion.placeholder"
                    :disabled-date="disableDateAfterNow"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item
                  label="Hobi"
                  prop="data.hobbies"
                  :rules="[{ required: true, message: 'Tolong masukan hobi anda', trigger: ['change', 'blur'] }]"
                >
                  <el-input
                    v-model="formEditFlk.data.hobbies"
                    placeholder=""
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="Bersedia Ditempatkan diluar Kota"
                  prop="data.is_ready_out_of_city"
                  :rules="[{ required: true, message: 'Tolong masukan ketersediaan anda', trigger: ['change', 'blur'] }]"
                >
                  <el-select
                    class="w-100"
                    v-model="formEditFlk.data.is_ready_out_of_city"
                    placeholder=""
                  >
                    <el-option
                      label="Iya"
                      :value="true"
                    ></el-option>
                    <el-option
                      label="Tidak"
                      :value="false"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- SUSUNAN KELUARGA 1 -->
        <div
          class="card shadow mt-3"
          v-if="formEditFlk.data.marital_status === 'Menikah' || formEditFlk.data.marital_status === 'Janda' || formEditFlk.data.marital_status === 'Duda'"
        >
          <div class="card-header bg-transparent">
            <h3 class="mb-0">{{ firstFamily.type }}</h3>
          </div>
          <div class="card-body">
            <div
              class="mb-4"
              v-for="(family, index) in firstFamily.members"
              :key="family.relationship_status"
            >
              <div class="d-flex align-items-center">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + family.relationship_status }}</h3>
                <el-button
                  v-if="family.relationship_status !== 'Istri/Suami'"
                  @click="removeFamily(family.index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Nama Lengkap"
                    :prop="'families.'+ family.index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama anggota keluarga anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.families[family.index].name"></el-input>
                  </el-form-item>
                  <div class="row">
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tempat Lahir"
                        :prop="'families.'+ family.index +'.place_of_birth'"
                        :rules="[{ required: true, message: 'Tolong masukan tempat lahir anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-input v-model="formEditFlk.families[family.index].place_of_birth"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tanggal Lahir"
                        :prop="'families.'+ family.index +'.date_of_birth'"
                        :rules="[{ required: true, message: 'Tolong masukan tanggal lahir anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="formEditFlk.families[family.index].date_of_birth"
                          type="date"
                          placeholder=""
                          :disabled-date="disableDateAfterNow"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Pendidikan Terakhir"
                    :prop="'families.'+ family.index +'.last_education'"
                    :rules="[{ required: true, message: 'Tolong masukan pendidikan lahir anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-select
                      class="w-100"
                      v-model="formEditFlk.families[family.index].last_education"
                      placeholder=" "
                    >
                      <el-option
                        v-for="educationLevel in educationLevels"
                        :key="educationLevel.value"
                        :label="educationLevel.label"
                        :value="educationLevel.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="Pekerjaan"
                    :prop="'families.'+ family.index +'.job'"
                  >
                    <el-input v-model="formEditFlk.families[family.index].job"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewFamily(firstFamily.type)"
                >Tambah Anggota Keluarga</button>
              </div>
            </div>
          </div>
        </div>

        <!-- SUSUNAN KELUARGA 2 -->
        <div
          class="card shadow mt-3"
          v-if="secondFamily.members.length> 0"
        >
          <div class="card-header bg-transparent">
            <h3 class="mb-0">{{ secondFamily.type }}</h3>
          </div>
          <div class="card-body">
            <div
              class="mb-4"
              v-for="(family, index) in secondFamily.members"
              :key="family.relationship_status"
            >
              <div class="d-flex align-items-center">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + family.relationship_status }}</h3>
                <el-button
                  v-if="family.relationship_status !== 'Ayah' && family.relationship_status !== 'Ibu'"
                  @click="removeFamily(family.index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Nama Lengkap"
                    :prop="'families.'+ family.index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama anggota keluarga anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.families[family.index].name"></el-input>
                  </el-form-item>
                  <div class="row">
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tempat Lahir"
                        :prop="'families.'+ family.index +'.place_of_birth'"
                        :rules="[{ required: true, message: 'Tolong masukan tempat lahir anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-input v-model="formEditFlk.families[family.index].place_of_birth"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tanggal Lahir"
                        :prop="'families.'+ family.index +'.date_of_birth'"
                        :rules="[{ required: true, message: 'Tolong masukan tanggal lahir anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="formEditFlk.families[family.index].date_of_birth"
                          type="date"
                          placeholder=""
                          :disabled-date="disableDateAfterNow"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Pendidikan Terakhir"
                    :prop="'families.'+ family.index +'.last_education'"
                    :rules="[{ required: true, message: 'Tolong masukan pendidikan lahir anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-select
                      class="w-100"
                      v-model="formEditFlk.families[family.index].last_education"
                      placeholder=" "
                    >
                      <el-option
                        v-for="educationLevel in educationLevels"
                        :key="educationLevel.value"
                        :label="educationLevel.label"
                        :value="educationLevel.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="Pekerjaan"
                    :prop="'families.'+ family.index +'.job'"
                  >
                    <el-input v-model="formEditFlk.families[family.index].job"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewFamily(secondFamily.type)"
                >Tambah Anggota Keluarga</button>
              </div>
            </div>

            <hr>

            <div
              v-for="familyQuestion in familyQuestions"
              :key="'fqi-' + familyQuestion.question_code"
            >
              <el-form-item
                :label="familyQuestion.question"
                :prop="'another_questions.'+ familyQuestion.index +'.answer'"
              >
                <el-input
                  :rows="2"
                  type="textarea"
                  placeholder="Jawaban"
                  v-model="formEditFlk.another_questions[familyQuestion.index].answer"
                ></el-input>
              </el-form-item>
            </div>

          </div>
        </div>

        <!-- MEDIA SOSSIAL -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Media Sosial</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                class="col-lg-4"
                v-for="(socmed, index) in formEditFlk.socmeds"
                :key="socmed.name"
              >
                <el-form-item
                  :label="socmed.name.charAt(0).toUpperCase() + socmed.name.slice(1)"
                  :prop="'socmeds.'+ index +'.username'"
                >
                  <el-input v-model="formEditFlk.socmeds[index].username"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- RIWAYAT PENDIDIKAN -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Riwayat Pendidikan</h3>
          </div>
          <div class="card-body">
            <div
              class="mb-4"
              v-for="(education_history, index) in formEditFlk.education_histories"
              :key="education_history.education_type"
            >
              <div class="d-flex align-items-center">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + education_history.education_type }}</h3>
                <el-button
                  v-if="education_history.education_type !== 'SD' && education_history.education_type !== 'SMP/SLTP' && education_history.education_type !== 'SMA/SMK/SLTA'"
                  @click="removeEducationHistory(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    :label="education_history.education_type !== 'Universitas' ? 'Nama Sekolah' : 'Nama Universitas'"
                    :prop="'education_histories.'+ index +'.education_name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama tempat pendidikan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.education_histories[index].education_name"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Kota/Kabupaten"
                    :prop="'education_histories.'+ index +'.city'"
                    :rules="[{ required: true, message: 'Tolong masukan kota/kabupaten pendidikan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.education_histories[index].city"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="education_history.education_type === 'SMA/SMK/SLTA' || education_history.education_type === 'Universitas'"
                    label="Jurusan"
                    :prop="'education_histories.'+ index +'.major'"
                  >
                    <el-input v-model="formEditFlk.education_histories[index].major"></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Tahun Kelulusan"
                    :prop="'education_histories.'+ index +'.graduation_year'"
                    :rules="[{ required: true, message: 'Tolong masukan tahun kelulusan pendidikan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      @keypress="onlyNumber($event)"
                      v-model="formEditFlk.education_histories[index].graduation_year"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Dibiayai Oleh"
                    :prop="'education_histories.'+ index +'.paid_by'"
                    :rules="[{ required: true, message: 'Tolong masukan dibiayai oleh siapa pendidikan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.education_histories[index].paid_by"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewEducationHistory"
                >Tambah Riwayat Pendidikan</button>
              </div>
            </div>

            <hr>

            <div
              v-for="educationHistoryQuestion in educationHistoryQuestions"
              :key="'ehqi-' + educationHistoryQuestion.question_code"
            >
              <el-form-item
                :label="educationHistoryQuestion.question"
                :prop="'another_questions.'+ educationHistoryQuestion.index +'.answer'"
              >
                <el-input
                  :rows="2"
                  type="textarea"
                  placeholder="Jawaban"
                  v-model="formEditFlk.another_questions[educationHistoryQuestion.index].answer"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <!-- KURSUS/PELATIHAN -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Kursus/Training</h3>
          </div>
          <div class="card-body">
            <div
              v-for="(course, index) in formEditFlk.courses"
              :key="course.course_name"
            >
              <div class="text-right">
                <el-button
                  @click="removeCourse(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Nama Kursus"
                    :prop="'courses.'+ index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama kursus anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.courses[index].name"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Lama Kursus (Bulan)"
                    :prop="'courses.'+ index +'.course_periode'"
                    :rules="[{ required: true, message: 'Tolong masukan lama kursus anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.courses[index].course_periode"></el-input>
                  </el-form-item>
                  <div class="row">
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tanggal Mulai"
                        :prop="'courses.'+ index +'.start_date'"
                        :rules="[{ required: true, message: 'Tolong masukan tanggal mulai kursus anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="formEditFlk.courses[index].start_date"
                          type="date"
                          placeholder=""
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6">
                      <el-form-item
                        label="Tanggal Selesai"
                        :prop="'courses.'+ index +'.end_date'"
                        :rules="[{ required: true, message: 'Tolong masukan tanggal selesai kursus anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-date-picker
                          class="w-100"
                          v-model="formEditFlk.courses[index].end_date"
                          type="date"
                          placeholder=""
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <el-form-item
                    label="Dibiayai Oleh"
                    :prop="'courses.'+ index +'.paid_by'"
                    :rules="[{ required: true, message: 'Tolong masukan dibiayai oleh siapa kursus anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.courses[index].paid_by"></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Penyelenggara"
                    :prop="'courses.'+ index +'.organizer'"
                    :rules="[{ required: true, message: 'Tolong masukan penyelenggara kursus anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.courses[index].organizer"></el-input>
                  </el-form-item>
                  <div>
                    <el-upload
                      :ref="'uploadFileCourse' + index"
                      class="w-100"
                      name="files[0]"
                      accept="application/pdf"
                      :action="uploadCourseFile.apiUrl"
                      :headers="uploadCourseFile.headers"
                      :data="{ index_number: index }"
                      :on-success="uploadCourseFileSuccess"
                      :on-error="uploadCourseFileError"
                      :on-remove="uploadCourseFileRemove"
                      :before-upload="beforeUploadCourseFile"
                      :file-list="formEditFlk.courses[index].medias"
                      multiple
                      drag
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">Klik Disini untuk Menambahkan File</div>
                    </el-upload>
                  </div>
                </div>
              </div>

              <hr v-if="(index + 1) !== formEditFlk.courses.length" />
            </div>
            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewCourse"
                >Tambah Kursus/Pelatihan</button>
              </div>
            </div>
          </div>
        </div>

        <!-- PRESTASI -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Prestasi</h3>
          </div>
          <div class="card-body">
            <div
              v-for="(achievement, index) in formEditFlk.achievements"
              :key="achievement.achievement_name"
            >
              <div class="text-right">
                <el-button
                  @click="removeAchievment(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Prestasi Yang Diraih"
                    :prop="'achievements.'+ index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama Prestasi Yang Diraih anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.achievements[index].name"></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Tahun"
                    :prop="'achievements.'+ index +'.year'"
                    :rules="[{ required: true, message: 'Tolong masukan tahun Prestasi Yang Diraih anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      @keypress="onlyNumber($event)"
                      v-model="formEditFlk.achievements[index].year"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <hr v-if="(index + 1) !== formEditFlk.achievements.length" />
            </div>
            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewAchievment"
                >Tambah Prestasi</button>
              </div>
            </div>
          </div>
        </div>

        <!-- BAHASA DIKUASAI -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Bahasa Yang Dikuasai</h3>
          </div>
          <div class="card-body">
            <div
              v-for="(language, index) in formEditFlk.languages"
              :key="language.language_name"
            >
              <div class="text-right">
                <el-button
                  @click="removeLanguage(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <el-form-item
                    label="Nama Bahasa"
                    :prop="'languages.'+ index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama bahasa dikuasai anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.languages[index].name"></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4">
                  <el-form-item
                    label="Kecakapan Bahasa (Tertulis)"
                    :prop="'languages.'+ index +'.writing_level'"
                    :rules="[{ required: true, message: 'Tolong masukan kecakapan bahasa tertulis anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-select
                      class="w-100"
                      v-model="formEditFlk.languages[index].writing_level"
                      placeholder="&nbsp;"
                    >
                      <el-option
                        v-for="languageProficiency in languageProficiencies"
                        :key="'lpws-' + index + '-' + languageProficiency.writing_level"
                        :label="languageProficiency.label"
                        :value="languageProficiency.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-lg-4">
                  <el-form-item
                    label="Kecakapan Bahasa (Lisan)"
                    :prop="'languages.'+ index +'.speaking_level'"
                    :rules="[{ required: true, message: 'Tolong masukan kecakapan bahasa lisan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-select
                      class="w-100"
                      v-model="formEditFlk.languages[index].speaking_level"
                      placeholder="&nbsp;"
                    >
                      <el-option
                        v-for="languageProficiency in languageProficiencies"
                        :key="'lpws-' + index + '-' + languageProficiency.speaking_level"
                        :label="languageProficiency.label"
                        :value="languageProficiency.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>

              <hr v-if="(index + 1) !== formEditFlk.languages.length" />
            </div>
            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewLanguage"
                >Tambah Bahasa Dikuasai</button>
              </div>
            </div>
          </div>
        </div>

        <!-- RIWAYAT PEKERJAAN -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Riwayat Pekerjaan
            </h3>
          </div>
          <div class="card-body">
            <div
              v-for="(job_history, index) in formEditFlk.job_histories"
              :key="job_history.company_name"
            >
              <div class="text-right">
                <el-button
                  @click="removeJobHistory(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Nama Perusahaan"
                    :prop="'job_histories.'+ index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.job_histories[index].name"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Alamat"
                    :prop="'job_histories.'+ index +'.address'"
                    :rules="[{ required: true, message: 'Tolong masukan alamat riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="formEditFlk.job_histories[index].address"
                    ></el-input>
                  </el-form-item>
                  <div class="row">
                    <div class="col-lg-6">
                      <el-form-item
                        label="Dari (Tahun)"
                        :prop="'job_histories.'+ index +'.start_year'"
                        :rules="[{ required: true, message: 'Tolong masukan tahun masuk di riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-input
                          @keypress="onlyNumber($event)"
                          v-model="formEditFlk.job_histories[index].start_year"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-6">
                      <el-form-item
                        label="Sampai (Tahun)"
                        :prop="'job_histories.'+ index +'.end_year'"
                        :rules="[{ required: true, message: 'Tolong masukan tahun selesai di riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                      >
                        <el-input
                          @keypress="onlyNumber($event)"
                          v-model="formEditFlk.job_histories[index].end_year"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Gaji Terakhir"
                    :prop="'job_histories.'+ index +'.last_salary'"
                    :rules="[{ required: true, message: 'Tolong masukan gaji terakhir di riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      v-model="formEditFlk.job_histories[index].last_salary"
                      @input="formEditFlk.job_histories[index].last_salary = formatRupiah(formEditFlk.job_histories[index].last_salary, 'Rp. ')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Posisi Terakhir"
                    :prop="'job_histories.'+ index +'.position'"
                    :rules="[{ required: true, message: 'Tolong masukan posisi terakhir di riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.job_histories[index].position"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Alasan Keluar"
                    :prop="'job_histories.'+ index +'.reason_of_resigning'"
                    :rules="[{ required: true, message: 'Tolong masukan alasan keluar di riwayat perusahaan anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="formEditFlk.job_histories[index].reason_of_resigning"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <el-upload
                    :ref="'uploadFileJobHistory' + index"
                    class="upload-job-history"
                    name="files[0]"
                    accept="application/pdf"
                    :data="{ index_number: index }"
                    :action="uploadJobHistoryFile.apiUrl"
                    :headers="uploadJobHistoryFile.headers"
                    :on-success="uploadJobHistoryFileSuccess"
                    :on-error="uploadJobHistoryFileError"
                    :on-remove="uploadJobHistoryFileRemove"
                    :before-upload="beforeUploadJobHistoryFile"
                    :file-list="formEditFlk.job_histories[index].medias"
                    multiple
                    drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Klik Disini untuk Menambahkan File</div>
                  </el-upload>
                </div>
              </div>

              <hr v-if="(index + 1) !== formEditFlk.job_histories.length" />
            </div>
            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewJobHistory"
                >Tambah Riwayat Pekerjaan</button>
              </div>
            </div>

            <hr>

            <div
              v-for="jobHistoryQuestion in jobHistoryQuestions"
              :key="'jhqi-' + jobHistoryQuestion.question_code"
            >
              <el-form-item
                :label="jobHistoryQuestion.question"
                :prop="'another_questions.'+ jobHistoryQuestion.index +'.answer'"
              >
                <el-input
                  :rows="2"
                  type="textarea"
                  placeholder="Jawaban"
                  v-model="formEditFlk.another_questions[jobHistoryQuestion.index].answer"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <!-- ORGANIZATION -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Riwayat Organisasi</h3>
          </div>
          <div class="card-body">
            <div
              v-for="(organization, index) in formEditFlk.organizations"
              :key="organization.organization_name"
            >
              <div class="text-right">
                <el-button
                  @click="removeOrganization(index)"
                  class="ml-auto"
                  type="danger"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item
                    label="Nama Organisasi"
                    :prop="'organizations.'+ index +'.name'"
                    :rules="[{ required: true, message: 'Tolong masukan nama riwayat organisasi anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.organizations[index].name"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Jabatan"
                    :prop="'organizations.'+ index +'.position'"
                    :rules="[{ required: true, message: 'Tolong masukan posisi di riwayat organisasi anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input v-model="formEditFlk.organizations[index].position"></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6">
                  <el-form-item
                    label="Keterangan"
                    :prop="'organizations.'+ index +'.note'"
                    :rules="[{ required: true, message: 'Tolong masukan keterangan di riwayat organisasi anda', trigger: ['change', 'blur'] }]"
                  >
                    <el-input
                      type="textarea"
                      :rows="7"
                      v-model="formEditFlk.organizations[index].note"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <hr v-if="(index + 1) !== formEditFlk.organizations.length" />
            </div>

            <div class="row justify-content-center pt-2">
              <div class="col-xl-3 col-md-6">
                <button
                  class="btn btn-info w-100"
                  type="button"
                  @click="addNewOrganization"
                >Tambah Riwayat Organisasi</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Interest And Talent -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Minat & Konsep Pribadi
            </h3>
          </div>
          <div class="card-body">
            <div
              v-for="interestAndTalentQuestion in interestAndTalentQuestions"
              :key="'iatqi-' + interestAndTalentQuestion.question_code"
            >
              <el-form-item
                :label="interestAndTalentQuestion.question"
                :prop="'another_questions.'+ interestAndTalentQuestion.index +'.name'"
              >
                <el-input
                  :rows="2"
                  type="textarea"
                  placeholder="Jawaban"
                  v-model="formEditFlk.another_questions[interestAndTalentQuestion.index].answer"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <!-- Social Activity -->
        <div class="card shadow mt-3">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Aktifitas Sosial dan Kegiatan-Kegiatan Lain
            </h3>
          </div>
          <div class="card-body">
            <div
              v-for="socialActivityQuestion in socialActivityQuestions"
              :key="'saqi-' + socialActivityQuestion.question_code"
            >
              <el-form-item
                :label="socialActivityQuestion.question"
                :prop="'another_questions.'+ socialActivityQuestion.index +'.answer'"
              >
                <el-input
                  :rows="2"
                  type="textarea"
                  placeholder="Jawaban"
                  v-model="formEditFlk.another_questions[socialActivityQuestion.index].answer"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <base-button
            native-type="submit"
            :disabled="updateFlkPromised.state.loading"
            type="primary"
          >{{ !updateFlkPromised.state.loading ? 'Simpan Perubahan' : 'Simpan Perubahan...' }}</base-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";

import { useStore } from "vuex";
import { useMeta } from "vue-meta";
import { useRoute, useRouter } from "vue-router";
import { formatRupiah, formatRupiahToInteger, onlyNumber } from '@/composables/useStringFormat'
import useAxios from "@/composables/useAxios";
import usePageMeta from "@/composables/usePageMeta";
import useSwal from "@/composables/useSwal";
import { firstFamilyType, secondFamilyType, dataInputTypeName, staticInputValue } from '@/composables/useFlk'
import { disableDateAfterNow, disabledByDateStart } from '@/composables/useElementDatePicker'

import FlkService from '@/services/Flk'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()

    const initFormEditFlk = () => {
      return {
        data: {
          name: '',
          place_of_birth: '',
          date_of_birth: '',
          phone: '',
          email: '',
          address: '',
          ktp_number: '',
          ktp_valid_from: '',
          ktp_valid_until: '',
          sim_number: '',
          sim_published_at: '',
          nationality: '',
          gender: '',
          religion: '',
          blood_type: '',
          live_with: '',
          marital_status: '',
          hobbies: '',
          is_ready_out_of_city: ''
        },
        job_position: {
          expected_salary: '',
          job_vacancy_id: '',
          job_vacancy_title: '',
          last_education: '',
        },
        families: [],
        courses: [],
        achievements: [],
        language: [],
        job_histories: [],
        organizations: [],
        another_questions: []
      }
    }

    const baseUrlApi = computed(() => store.getters['config/baseUrlAPi'])

    const state = reactive({
      formEditFlk: initFormEditFlk(),
      uploadProfilePhoto: {
        apiUrl: baseUrlApi.value + '/api/superadmin/flk/file?type=profile-photo',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + store.getters['auth/accessToken']
        },
        fileUrl: '',
        data: {
          name: 'profile-photo',
          value: '',
          type: 'image'
        }
      },
      uploadCourseFile: {
        apiUrl: baseUrlApi.value + '/api/superadmin/flk/file?type=course-certificate',
        headers: {
          Authorization: 'Bearer ' + store.getters['auth/accessToken']
        },
      },
      uploadJobHistoryFile: {
        apiUrl: baseUrlApi.value + '/api/superadmin/flk/file?type=job-certificate',
        headers: {
          Authorization: 'Bearer ' + store.getters['auth/accessToken']
        },
      }
    })
    useMeta(usePageMeta(route).pageMeta)

    const paramId = computed(() => route.params.id)
    const formEditFlkRef = ref()

    const getIndexAnotherQuestion = () => {
      return 0
    }

    // PESONAL DATA
    const personalDataState = reactive({
      religions: staticInputValue.religions,
      genders: staticInputValue.genders,
      bloodTypes: staticInputValue.bloodTypes,
      nationalities: staticInputValue.nationalities,
      liveWiths: staticInputValue.liveWiths,
      maritalStatuses: staticInputValue.maritalStatuses
    })

    // Upload Photo
    const uploadPPSuccess = (res) => {
      if (res.data.files.length > 0) {
        state.uploadProfilePhoto.data.value = res.data.files[0].filename
        state.uploadProfilePhoto.fileUrl = res.data.files[0].url
      }
    }

    // KTP
    const handleChangeKTPValidDateStart = () => {
      state.formEditFlk.data.ktp_valid_until = ''
    }

    // Live With
    const liveWithQuestion = computed(() => {
      let question = {
        data: null,
        index: -1,
        placeholder: '',
        validationMessages: {
          required: ''
        }
      }

      switch (state.formEditFlk.data.live_with) {
        case 'Lainnya':
          question.validationMessages.required = 'Tolong masukan input tinggal dengan lainnya'

          state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'lw-01') {
              question.data = q
              question.index = index
              return true
            }
          })
          break;

        case 'Kost':
          question.validationMessages.required = 'Tolong masukan tanggal dimulai & biaya kost'
          question.placeholder = '1 Januari 2021,  Rp 2.000.000'

          state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'lw-02') {
              question.data = q
              question.index = index
              return true
            }
          })
          break;

        case 'Kontrak':
          question.validationMessages.required = 'Tolong masukan tanggal dimulai & biaya kontrak'
          question.placeholder = '1 Januari 2021,  Rp 2.000.000'

          state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'lw-03') {
              question.data = q
              question.index = index
              return true
            }
          })
          break;

        default:
          question = {
            data: null,
            index: -1,
            validationMessages: {
              required: ''
            }
          }
          break

      }

      return question
    })

    // Marital Status
    const handleChangeMaritalStatus = (value) => {
      state.formEditFlk.families = state.formEditFlk.families.filter((family) => {
        return family.family_type !== 'Istri/Suami dan Anak-anak'
      })

      if (value === 'Menikah' && state.formEditFlk.families.filter((family) => family.relationship_status === 'Istri/Suami').length === 0) {
        state.formEditFlk.families.push({
          name: "",
          relationship_status: "Istri/Suami",
          family_type: firstFamilyType,
          place_of_birth: "",
          date_of_birth: "",
          last_education: "",
          job: ""
        })
      }

      // if (value !== 'Menikah') {
      //   const wifeAndHusbandValueIndex = state.formEditFlk.families.findIndex((family) => family.relationship_status === 'Istri/Suami')
      //   if (wifeAndHusbandValueIndex > -1) {
      //     state.formEditFlk.families.splice(wifeAndHusbandValueIndex, 1)
      //   }
      // }
    }

    const maritalStatusQuestion = computed(() => {
      let question = {
        data: null,
        index: -1,
        placeholder: '',
        validationMessages: {
          required: ''
        }
      }
      let selectedQuestion = null

      switch (state.formEditFlk.data.marital_status) {
        case 'Bertunangan':
          question.validationMessages.required = 'Tolong masukan tanggal bertunangan anda'

          selectedQuestion = state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'ms-02') {
              question.data = q
              question.index = index
              return true
            }
          })

          if (selectedQuestion.length <= 0) {
            state.formEditFlk.another_questions.push({
              question_code: 'ms-02',
              answer: ''
            })
          }

          break;

        case 'Menikah':
          question.validationMessages.required = 'Tolong masukan tanggal pernikahan anda'

          state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'ms-01') {
              question.data = q
              question.index = index
              return true
            }
          })
          break;

        case 'Duda':
        case 'Janda':
          question.validationMessages.required = 'Tolong masukan tanggal perceraian anda'

          state.formEditFlk.another_questions.filter((q, index) => {
            if (q.question_code === 'ms-03') {
              question.data = q
              question.index = index
              return true
            }
          })
          break;

        default:
          question = {
            data: null,
            index: -1,
            validationMessages: {
              required: ''
            }
          }
          break;
      }

      return question
    })

    // FAMILY
    const firstFamily = computed(() => {
      return {
        type: firstFamilyType,
        members: state.formEditFlk.families.map((family, index) => {
          return { ...family, index }
        })
          .filter(family => family.family_type === firstFamilyType)
      }
    })

    const secondFamily = computed(() => {
      return {
        type: secondFamilyType,
        members: state.formEditFlk.families.map((family, index) => {
          return { ...family, index }
        })
          .filter(family => family.family_type === secondFamilyType)
      }
    })

    const addNewFamily = (type) => {
      switch (type) {
        case secondFamilyType:
          if (state.formEditFlk.families.filter(family => family.relationship_status === 'Ayah').length <= 0) {
            state.formEditFlk.families.push({
              name: "",
              relationship_status: "Ayah",
              family_type: secondFamilyType,
              place_of_birth: "",
              date_of_birth: "",
              last_education: "",
              job: ""
            })
          } else if (state.formEditFlk.families.filter(family => family.relationship_status === 'Ibu').length <= 0) {
            state.formEditFlk.families.push({
              name: "",
              relationship_status: "Ibu",
              family_type: secondFamilyType,
              place_of_birth: "",
              date_of_birth: "",
              last_education: "",
              job: ""
            })
          } else {
            state.formEditFlk.families.push({
              name: "",
              relationship_status: "Lainnya",
              family_type: secondFamilyType,
              place_of_birth: "",
              date_of_birth: "",
              last_education: "",
              job: ""
            })
          }
          break;

        case firstFamilyType:
          if (state.formEditFlk.families.filter(family => family.relationship_status === 'Istri/Suami').length <= 0 && state.formEditFlk.data.marital_status === 'Menikah') {
            state.formEditFlk.families.push({
              name: "",
              relationship_status: "Istri/Suami",
              family_type: firstFamilyType,
              place_of_birth: "",
              date_of_birth: "",
              last_education: "",
              job: ""
            })
          } else {
            state.formEditFlk.families.push({
              name: "",
              relationship_status: 'Anak',
              family_type: firstFamilyType,
              place_of_birth: "",
              date_of_birth: "",
              last_education: "",
              job: ""
            })
          }
          break;
      }
    }

    const removeFamily = (index) => {
      state.formEditFlk.families.splice(index, 1)
    }

    const familyQuestions = computed(() => {
      return state.formEditFlk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.familyDataInput
      })
    })

    // EDUCATION HISTORY
    const eductionState = reactive({
      educationLevels: staticInputValue.educationLevels
    })
    const addNewEducationHistory = () => {
      if (state.formEditFlk.education_histories.filter(education_history => education_history.education_type === 'SD').length <= 0) {
        state.formEditFlk.education_histories.push({
          education_type: 'SD',
          education_name: '',
          city: '',
          graduation_year: '',
          paid_by: '',
          major: ''
        })
      } else if (state.formEditFlk.education_histories.filter(education_history => education_history.education_type === 'SMP/SLTP').length <= 0) {
        state.formEditFlk.education_histories.push({
          education_type: 'SMP/SLTP',
          education_name: '',
          city: '',
          graduation_year: '',
          paid_by: '',
          major: ''
        })
      } else if (state.formEditFlk.education_histories.filter(education_history => education_history.education_type === 'SMA/SMK/SLTA').length <= 0) {
        state.formEditFlk.education_histories.push({
          education_type: 'SMA/SMK/SLTA',
          education_name: '',
          city: '',
          graduation_year: '',
          paid_by: '',
          major: ''
        })
      } else {
        state.formEditFlk.education_histories.push({
          education_type: 'Universitas',
          education_name: '',
          city: '',
          graduation_year: '',
          paid_by: '',
          major: ''
        })
      }
    }

    const removeEducationHistory = (index) => {
      state.formEditFlk.education_histories.splice(index, 1)
    }

    const educationHistoryQuestions = computed(() => {
      return state.formEditFlk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.educationHistoryInput
      })
    })

    // CORUSE
    const addNewCourse = () => {
      state.formEditFlk.courses.push({
        name: '',
        course_periode: '',
        start_date: '',
        end_date: '',
        paid_by: '',
        organizer: '',
        medias: [],
      })
    }

    const removeCourse = (index) => {
      state.formEditFlk.courses.splice(index, 1)
    }

    const beforeUploadCourseFile = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 1500,
          icon: 'warning',
          imageHeight: 50,
          title: 'Upload file kursus tidak boleh melebihi 2MB',
        })
      }
      return isLt2M
    }

    const uploadCourseFileRemove = (file) => {
      let courseIndex = -1, mediaIndex = -1

      state.formEditFlk.courses.map((course, index) => {
        course.medias.map((media, index2) => {
          if (media.value === file.value) {
            mediaIndex = index2
            courseIndex = index
          }
        })
      })

      if (courseIndex > -1 && mediaIndex > -1) {
        state.formEditFlk.courses[courseIndex].medias.splice(mediaIndex, 1)
      }
    }

    const uploadCourseFileSuccess = (res) => {
      if (res.data.files.length > 0) {
        const activeIndex = Number(res.data.index_number)
        state.formEditFlk.courses[activeIndex].medias.push({
          name: "course-certificate",
          value: res.data.files[0].filename,
          type: "file"
        })
      }
    }

    const uploadCourseFileError = () => {
      Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 1500,
        icon: 'warning',
        imageHeight: 50,
        title: 'Gagal ketika mengunggah file',
      })
    }

    // ACHIEVMENT
    const addNewAchievment = () => {
      state.formEditFlk.achievements.push({
        name: '',
        year: ''
      })
    }

    const removeAchievment = (index) => {
      state.formEditFlk.achievements.splice(index, 1)
    }

    // LANGUAGE
    const languageState = reactive({
      languageProficiencies: staticInputValue.languageProficiencies
    })
    const addNewLanguage = () => {
      state.formEditFlk.languages.push({
        language_name: '',
        is_active: ''
      })
    }

    const removeLanguage = (index) => {
      state.formEditFlk.languages.splice(index, 1)
    }

    // JOB HISTORY
    const addNewJobHistory = () => {
      state.formEditFlk.job_histories.push({
        company_name: '',
        address: '',
        last_salary: '',
        position: '',
        start_year: '',
        end_year: '',
        reason_of_resigning: '',
        medias: []
      })
    }

    const removeJobHistory = (index) => {
      state.formEditFlk.job_histories.splice(index, 1)
    }

    const beforeUploadJobHistoryFile = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        Swal.fire({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 1500,
          icon: 'warning',
          imageHeight: 50,
          title: 'Upload file riwayat pekerjaan tidak boleh melebihi 2MB',
        })
      }
      return isLt2M
    }

    const uploadJobHistoryFileRemove = (file) => {
      let jobHistoryIndex = -1, mediaIndex = -1

      state.formEditFlk.job_histories.map((jobHistory, index) => {
        jobHistory.medias.map((media, index2) => {
          if (media.value === file.value) {
            mediaIndex = index2
            jobHistoryIndex = index
          }
        })
      })

      if (jobHistoryIndex > -1 && mediaIndex > -1) {
        state.formEditFlk.job_histories[jobHistoryIndex].medias.splice(mediaIndex, 1)
      }
    }

    const uploadJobHistoryFileSuccess = (res) => {
      if (res.data.files.length > 0) {
        const activeIndex = Number(res.data.index_number)
        state.formEditFlk.job_histories[activeIndex].medias.push({
          name: "job-certificate",
          value: res.data.files[0].filename,
          type: "file"
        })
      }
    }

    const uploadJobHistoryFileError = () => {
      Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 1500,
        icon: 'warning',
        imageHeight: 50,
        title: 'Gagal ketika mengunggah file',
      })
    }

    const jobHistoryQuestions = computed(() => {
      return state.formEditFlk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.jobHistoryInput
      })
    })

    // ORGANIZAION
    const addNewOrganization = () => {
      state.formEditFlk.organizations.push({
        organization_name: '',
        position: '',
        note: ''
      })
    }

    const removeOrganization = (index) => {
      state.formEditFlk.organizations.splice(index, 1)
    }

    // Interest And Talent
    const interestAndTalentQuestions = computed(() => {
      return state.formEditFlk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.interestAndTalentInput
      })
    })

    // Social Activity
    const socialActivityQuestions = computed(() => {
      return state.formEditFlk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.socialActivityInput
      })
    })

    const getFlkPromised = useAxios((id, payload = {}) => {
      return FlkService.superadmin.getFlk(id, payload)
    })
    const getFlk = async () => {
      await getFlkPromised.createPromise(paramId.value)

      if (getFlkPromised.state.response) {
        let phoneNumber = getFlkPromised.state.data.phone
        if (String(phoneNumber).slice(0, 2) == '62') {
          phoneNumber = phoneNumber.slice(2, phoneNumber.length)
        }


        const profilePhoto = getFlkPromised.state.data.medias.filter((media) => {
          return media.name === 'profile-photo'
        })

        if (profilePhoto.length > 0) {
          state.uploadProfilePhoto.fileUrl = profilePhoto[0].url
          state.uploadProfilePhoto.data.value = profilePhoto[0].value.replace('profile-photo/', '')
        }

        const socmeds = getFlkPromised.state.data.socmeds
        const socmedsPlatform = ['instagram', 'facebook', 'linkedin']
        socmedsPlatform.map(sp => {
          if (socmeds.filter(socmed => socmed.name === sp).length <= 0) {
            socmeds.push({
              name: sp,
              username: ''
            })
          }
        })

        const courses = getFlkPromised.state.data.courses.map(course => {
          return {
            ...course,
            name: course.course_name,
            medias: course.medias.map(media => {
              return {
                ...media,
                value: media.value.replace('course-certificate/', '')
              }
            })
          }
        })

        const achievements = getFlkPromised.state.data.achievements.map(achievement => {
          return {
            ...achievement,
            name: achievement.achievement_name
          }
        })

        const languages = getFlkPromised.state.data.languages.map(language => {
          return {
            ...language,
            name: language.language_name
          }
        })

        const educationHistories = getFlkPromised.state.data.education_histories.sort((a, b) => {
          return (a.graduation_year > b.graduation_year) ? 1 : ((b.graduation_year > a.graduation_year) ? -1 : 0)
        })

        const jobHistories = getFlkPromised.state.data.job_histories.map(jobHistory => {
          return {
            ...jobHistory,
            name: jobHistory.company_name,
            last_salary: formatRupiah(jobHistory.last_salary, 'Rp. '),
            medias: jobHistory.medias.map(media => {
              return {
                ...media,
                value: media.value.replace('job-certificate/', '')
              }
            })
          }
        })

        const organizations = getFlkPromised.state.data.organizations.map(organization => {
          return {
            ...organization,
            name: organization.organization_name
          }
        })

        state.formEditFlk = {
          token: getFlkPromised.state.data.id,
          data: {
            name: getFlkPromised.state.data.name,
            place_of_birth: getFlkPromised.state.data.place_of_birth,
            date_of_birth: getFlkPromised.state.data.date_of_birth,
            phone: phoneNumber,
            email: getFlkPromised.state.data.email,
            address: getFlkPromised.state.data.address,
            ktp_number: getFlkPromised.state.data.ktp_number,
            ktp_published_at: getFlkPromised.state.data.ktp_published_at,
            ktp_valid_from: getFlkPromised.state.data.ktp_valid_from,
            ktp_valid_until: getFlkPromised.state.data.ktp_valid_until,
            sim_number: getFlkPromised.state.data.sim_number,
            sim_published_at: getFlkPromised.state.data.sim_published_at,
            nationality: getFlkPromised.state.data.nationality,
            gender: getFlkPromised.state.data.gender,
            religion: getFlkPromised.state.data.religion,
            blood_type: getFlkPromised.state.data.blood_type,
            live_with: getFlkPromised.state.data.live_with,
            marital_status: getFlkPromised.state.data.marital_status,
            hobbies: getFlkPromised.state.data.hobbies,
            is_ready_out_of_city: getFlkPromised.state.data.is_ready_out_of_city
          },
          job_position: {
            ...getFlkPromised.state.data.job_position,
            expected_salary: formatRupiah(getFlkPromised.state.data.job_position.expected_salary, 'Rp. ')
          },
          families: getFlkPromised.state.data.families,
          socmeds: socmeds,
          education_histories: educationHistories,
          courses: courses,
          achievements: achievements,
          languages: languages,
          job_histories: jobHistories,
          organizations: organizations,
          another_questions: getFlkPromised.state.data.another_questions.map((q, index) => {
            return {
              question_code: q.question_code,
              question_location: q.question_location,
              question: q.question,
              answer: q.answer,
              index
            }
          })
        }
      } else if (getFlkPromised.state.error) {
        await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed to get category',
            text: getFlkPromised.state.errorMessage,
            willClose: () => {
              router.push({
                name: 'Kelola Flk'
              })
            },
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }

    const updateFlkPromised = useAxios((id, payload = {}) => {
      return FlkService.superadmin.updateFlk(id, payload)
    })
    const updateFlk = async () => {
      formEditFlkRef.value.validate(async (valid) => {
        if (!valid) {
          return false
        }

        await updateFlkPromised.createPromise(paramId.value, {
          data: {
            ...state.formEditFlk,
            job_position: {
              ...state.formEditFlk.job_position,
              expected_salary: formatRupiahToInteger(state.formEditFlk.job_position.expected_salary, 'Rp. ')
            },
            medias: [state.uploadProfilePhoto.data],
            courses: state.formEditFlk.courses.map(course => {
              return {
                ...course,
                course_name: course.name,
              }
            }),
            achievements: state.formEditFlk.achievements.map(achievement => {
              return {
                achievement_name: achievement.name,
                year: achievement.year
              }
            }),
            job_histories: state.formEditFlk.job_histories.map(job_history => {
              return {
                ...job_history,
                company_name: job_history.name,
                last_salary: formatRupiahToInteger(job_history.last_salary, 'Rp. ')
              }
            }),
            languages: state.formEditFlk.languages.map(language => {
              return {
                ...language,
                language_name: language.name
              }
            }),
            organizations: state.formEditFlk.organizations.map(organization => {
              return {
                ...organization,
                organization_name: organization.name
              }
            })
          }
        })

        if (updateFlkPromised.state.response) {
          state.formEditFlk = initFormEditFlk()
          formEditFlkRef.value.resetFields()

          Swal.fire({
            icon: 'success',
            title: 'Successfully updated a flk',
            willClose: () => {
              router.push({
                name: 'Kelola Flk'
              })
            }
          })
        } else if (updateFlkPromised.state.error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to update flk',
            text: updateFlkPromised.state.errorMessage,
          })
        }
      })
    }

    onMounted(async () => {
      await getFlk()
    })

    return {
      ...toRefs(state),
      disableDateAfterNow,
      disabledByDateStart,
      getIndexAnotherQuestion,
      formatRupiah,
      onlyNumber,
      baseUrlApi,

      // Personal Data
      ...toRefs(personalDataState),
      uploadPPSuccess,
      handleChangeKTPValidDateStart,
      liveWithQuestion,
      handleChangeMaritalStatus,
      maritalStatusQuestion,

      // Family
      firstFamily,
      secondFamily,
      addNewFamily,
      removeFamily,
      familyQuestions,

      // Education History
      ...toRefs(eductionState),
      addNewEducationHistory,
      removeEducationHistory,
      educationHistoryQuestions,
      jobHistoryQuestions,

      // Course
      addNewCourse,
      removeCourse,
      uploadCourseFileRemove,
      uploadCourseFileSuccess,
      uploadCourseFileError,
      beforeUploadCourseFile,

      // Achievement
      addNewAchievment,
      removeAchievment,

      // Language
      ...toRefs(languageState),
      addNewLanguage,
      removeLanguage,

      // Job History
      addNewJobHistory,
      removeJobHistory,
      beforeUploadJobHistoryFile,
      uploadJobHistoryFileRemove,
      uploadJobHistoryFileSuccess,
      uploadJobHistoryFileError,

      // Organization
      addNewOrganization,
      removeOrganization,

      // Interest And Talent
      interestAndTalentQuestions,

      // Social Activity
      socialActivityQuestions,

      // Form Function
      formEditFlkRef,
      getFlkPromised,
      getFlk,
      updateFlkPromised,
      updateFlk
    }
  },
});
</script>

<style lang="scss">
.avatar-uploader {
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 267px;
  line-height: 267px;
  text-align: center;
}
.avatar-img {
  width: 178px;
  height: 267px;
  display: block;
}
.upload-job-history {
  width: 100%;
  text-align: center;
  margin: 15px 0;

  .el-upload-list.el-upload-list--text {
    text-align: left;
  }
}
</style>
