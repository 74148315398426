<template>
  <div class="pt-container">
    <canvas
      class="img-fluid"
      :id="_uid"
    />
    <a
      target="_blank"
      :href="fileUrl"
      class="btn btn-pdf-download"
    >Download</a>
    <div class="pt-blur"></div>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf.js'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`
import { genRandomStr } from '@/composables/useStringFormat'

export default {
  name: 'PdfThumbnail',
  props: {
    fileUrl: {
      type: String,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    _uid() {
      return genRandomStr()
    }
  },
  mounted() {
    this.renderThumbnails()
  },
  methods: {
    async renderThumbnails() {
      const worker = new pdfjsLib.PDFWorker()
      let pdf = await pdfjsLib
        .getDocument({ url: this.fileUrl, worker: worker })
        .promise.then((pdf) => pdf)
      const page = await pdf.getPage(1)
      let viewport = page.getViewport({ scale: 1.0 })
      viewport = page.getViewport({ scale: this.width / viewport.width })
      let canvas = document.getElementById(this._uid)
      canvas.height = viewport.height
      canvas.width = viewport.width
      const context = canvas.getContext('2d')
      await page.render({ canvasContext: context, viewport: viewport })
    },
  },
}
</script>

<style lang="scss" scoped>
.pt-container {
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  padding: 0 15px;
  height: 100%;

  .pt-blur {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .pt-blur:hover {
    background: #696868;
    opacity: 0.5;
  }

  .btn.btn-pdf-download {
    background: #ffffff;
    border: 1px solid #1659ab;
    box-sizing: border-box;
    border-radius: 40px;
    color: #1659ab;
    padding: 8px 27px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-55%, -47%);
    z-index: 2;
  }
}
</style>