<template>
  <div
    class="pf-container"
    v-if="flk.id"
  >
    <div class="nav-container">
      <div class="app-container">
        <button
          class="btn btn-print"
          @click="openPrintWindow"
        >Print</button>
      </div>
    </div>
    <print-header :flk="flk" />
    <print-applied-job :flk="flk" />
    <print-personal-data :flk="flk" />
    <print-education-histories :flk="flk" />
    <print-organization :flk="flk" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import useAxios from "@/composables/useAxios";
import useSwal from "@/composables/useSwal";
import usePageMeta from "@/composables/usePageMeta";
import { useRoute, useRouter } from "vue-router";
import { toRefs } from "@vueuse/core";
import { useMeta } from "vue-meta";

import flkService from '@/services/Flk'

import PrintHeader from '@/components/Flk/PrintHeader.vue'
import PrintAppliedJob from '@/components/Flk/PrintAppliedJob.vue'
import PrintPersonalData from '@/components/Flk/PrintPersonalData.vue'
import PrintEducationHistories from '@/components/Flk/PrintEducationHistories.vue'
import PrintOrganization from '@/components/Flk/PrintOrganization.vue'

export default defineComponent({
  components: {
    PrintHeader,
    PrintAppliedJob,
    PrintPersonalData,
    PrintEducationHistories,
    PrintOrganization
  },
  setup() {
    const Swal = useSwal()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      flk: {}
    })

    const paramId = computed(() => route.params.id)

    const pageMeta = computed(() => {
      if (!state.flk.id) {
        return usePageMeta(route).pageMeta
      }

      return {
        title: 'Flk - ' + state.flk.name + ' - ' + state.flk.job_position.job_vacancy_title + ' - Paninti Karir',
        meta: [
          {
            name: 'title',
            content: 'Flk - ' + state.flk.name + ' - ' + state.flk.job_position.job_vacancy_title + ' - Paninti Karir',
          },
          {
            name: 'description',
            content: state.flk.meta_description,
          },
          {
            name: 'keywords',
            content: state.flk.meta_keywords,
          },
        ],
      }
    })

    useMeta(pageMeta)

    const openPrintWindow = () => {
      window.print()
    }

    const getFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.getFlk(id, payload)
    })
    const getFlk = async () => {
      await getFlkPromised.createPromise(paramId.value)

      if (getFlkPromised.state.response) {
        state.flk = getFlkPromised.state.data
      } else if (getFlkPromised.state.error) {
        await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed to get job vacancy',
            text: getFlkPromised.state.errorMessage,
            willClose: () => {
              router.push({
                name: 'Kelola Flk'
              })
            },
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }

    onMounted(async () => {
      await getFlk()
    })

    return {
      ...toRefs(state),
      openPrintWindow,
      getFlk
    }
  }
})
</script>

<style lang="scss" scoped>
.pf-container {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  .nav-container {
    text-align: right;
    padding: 10px 0;

    .btn-print {
      padding: 10px 60px;
      border-radius: 20px;
      background: #1659ab;
      color: #fff;
    }
  }
}

@media print {
  .nav-container {
    display: none;
  }
}
</style>

<style lang="scss">
.app-container {
  width: 85%;
  margin: 0 auto !important;
}
.pf-card {
  padding: 16px 32px;
  border: 1px solid #b9d4f6;
  box-sizing: border-box;
  border-radius: 16px;
}
</style>