<template>
  <div class="ql">
    <p class="ql-question">
      {{ question }}
    </p>
    <p class="ql-answer-label">
      Jawaban
    </p>
    <p class="ql-answer">{{ answer }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "QuestionLabel",

  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.ql {
  &-question {
    font-family: map-get($font-array, "opensans");
    font-style: normal;
    font-weight: normal;
    font-size: map-get($font-size-array, "md");
    line-height: map-get($line-height-array, "md");
    margin-bottom: 10px;
    color: map-get($color-black-array, "01");
  }

  &-answer {
    font-family: map-get($font-array, "opensans");
    font-style: normal;
    font-weight: normal;
    font-size: map-get($font-size-array, "md");
    line-height: map-get($line-height-array, "md");
    color: map-get($color-grey-array, "02");
    margin-bottom: 0;

    &-label {
      font-family: map-get($font-array, "opensans");
      font-style: normal;
      font-weight: normal;
      font-size: map-get($font-size-array, "sm");
      line-height: map-get($line-height-array, "sm");
      color: map-get($color-grey-array, "01");
      margin-bottom: 2px;
    }
  }
}
</style>