<template>
  <div
    class="page-content"
    ref="pageContent"
  >
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>

    <div
      class="container-fluid mt--9"
      v-if="flk.id"
    >
      <div class="mb-3 d-flex justify-content-between">
        <div class="d-flex my-auto">
          <span class="label-reviewed mb-0 mr-3">Tandai sebagai ditinjau</span>
          <el-switch
            class="mt-1"
            v-model="flk.is_reviewed"
            :loading="review_loading"
            :beforeChange="reviewUnreviewFlk"
          >
          </el-switch>
        </div>
        <div>
          <button
            class="btn btn-edit-flk"
            @click="goToEditPage"
          >Ubah</button>
          <button
            class="btn btn-export-flk"
            @click="exportPdf"
          >Expor Ke Pdf</button>
        </div>
      </div>

      <div class="card card-df">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <base-label
                label="Posisi yang Diinginkan"
                :value="flk.job_position.job_vacancy_title"
              />
            </div>
            <div class="col-md-4">
              <base-label
                label="Pendidikan Terakhir"
                :value="flk.job_position.last_education"
              />
            </div>
            <div class="col-md-4">
              <base-label
                label="Gaji yang Diinginkan"
                :value="formatRupiah(flk.job_position.expected_salary, 'Rp ')"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- DATA DIRI -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Data Diri</h3>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-xl-2 text-center">
              <div class="mb-3">
                <img
                  class="applicant-photo"
                  :src="applicantsPhoto()"
                  alt="Applicant's Photo"
                >
              </div>
            </div>
            <div class="col-xl-5">
              <div class="mb-3">
                <base-label
                  label="Nama Lengkap"
                  :value="flk.name"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Tempat, Tanggal Lahir"
                  :value="flk.place_of_birth + ', ' + flk.date_of_birth"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="No Telepon"
                  :value="flk.phone"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Email"
                  :value="flk.email"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Alamat"
                  :value="flk.address"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Agama"
                  :value="flk.religion"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="No. KTP"
                  :value="flk.ktp_number"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="KTP Dikeluarkan Di"
                  :value="flk.ktp_published_at ? flk.ktp_published_at : '-'"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Masa Berlaku"
                  :value="flk.ktp_valid_from && flk.ktp_valid_until ? flk.ktp_valid_from + ' - ' + flk.ktp_valid_until : '-'"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="S.I.M Nomor"
                  :value="flk.sim_number ? flk.sim_number : '-'"
                />
              </div>
            </div>

            <div class="col-xl-5">
              <div class="mb-3">
                <base-label
                  label="Tanggal S.I.M Dikeluarkan"
                  :value="flk.sim_published_at ? flk.sim_published_at : '-'"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Golongan Darah"
                  :value="flk.blood_type"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Kewarganegaraan"
                  :value="flk.nationality"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Jenis Kelamin"
                  :value="flk.gender"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Tinggal Dengan"
                  :value="flk.live_with"
                />
              </div>
              <div
                class="mb-3"
                v-if="liveWithQuestion"
              >
                <base-label
                  :label="liveWithQuestion.question"
                  :value="liveWithQuestion.answer ? liveWithQuestion.answer : '-'"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Status pernikahan"
                  :value="flk.marital_status"
                />
              </div>
              <div
                class="mb-3"
                v-if="maritalStatusQuestion"
              >
                <base-label
                  :label="maritalStatusQuestion.question"
                  :value="maritalStatusQuestion.answer ? maritalStatusQuestion.answer : '-'"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Hobi"
                  :value="flk.hobbies"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Bersedia Ditempatkan diluar Kota"
                  :value="flk.is_ready_out_of_city === true ? 'Bersedia' : 'Tidak Bersedia'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SUSUNAN KELUARGA 1 -->
      <div
        class="card card-df mt-3"
        v-if="flk.marital_status === 'Menikah' || flk.marital_status === 'Janda' || flk.marital_status === 'Duda'"
      >
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">{{ firstFamily.type }}</h3>
          </div>
        </div>

        <div class="card-body">
          <div
            class="row mt-2"
            v-if="firstFamily.members.length > 0"
          >
            <div
              class="col-lg-4"
              v-for="(family, index) in firstFamily.members"
              :key="family.relationship_status"
            >
              <div>
                <h3>{{ String.fromCharCode(index + 65) + '. ' + family.relationship_status }}</h3>
              </div>

              <div class="mb-3">
                <base-label
                  label="Nama Lengkap"
                  :value="family.name"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Tempat, Tanggal Lahir"
                  :value="family.place_of_birth + ', ' + family.date_of_birth"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Pendidikan Terakhir"
                  :value="family.last_education"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Pekerjaan"
                  :value="family.job"
                />
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada susunan keluarga
          </div>
        </div>
      </div>

      <!-- SUSUNAN KELUARGA 2 -->
      <div
        class="card card-df mt-3"
        v-if="secondFamily.members.length> 0"
      >
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">{{ secondFamily.type }}</h3>
          </div>
        </div>

        <div class="card-body">
          <div
            class="row mt-2"
            v-if="secondFamily.members.length > 0"
          >
            <div
              class="col-lg-4"
              v-for="(family, index) in secondFamily.members"
              :key="family.relationship_status"
            >
              <div>
                <h3>{{ String.fromCharCode(index + 65) + '. ' + family.relationship_status }}</h3>
              </div>

              <div class="mb-3">
                <base-label
                  label="Nama Lengkap"
                  :value="family.name"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Tempat, Tanggal Lahir"
                  :value="family.place_of_birth + ', ' + family.date_of_birth"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Pendidikan Terakhir"
                  :value="family.last_education"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Pekerjaan"
                  :value="family.job"
                />
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada susunan keluarga
          </div>

          <hr>

          <template v-if="familyQuestions">
            <question-label
              v-for="(question) in familyQuestions"
              :key="'fq-' + question.question_code"
              class="mb-3"
              :question="question.question"
              :answer="question.answer ? question.answer : '-'"
            />
          </template>
        </div>
      </div>

      <!-- MEDIA SOSIAL -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Media Sosial</h3>
          </div>
        </div>

        <div class="card-body">
          <div
            class="row"
            v-if="flk.socmeds.length > 0"
          >
            <div
              class="col-md-4"
              v-for="socmed in flk.socmeds"
              :key="socmed.name"
            >
              <div class="mb-3">
                <base-label
                  :label="socmed.name.charAt(0).toUpperCase() + socmed.name.slice(1)"
                  :value="socmed.username ? socmed.username : '-'"
                />
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada sosial media
          </div>
        </div>
      </div>

      <!-- RIWAYAT PENDIDIKAN -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Riwayat Pendidikan</h3>
          </div>
        </div>

        <div class="card-body">
          <template v-if="educationHistories.length > 0">
            <div class="row">
              <div
                class="col-md-4"
                v-for="(education_history, index) in educationHistories"
                :key="education_history.education_type + index"
              >
                <div>
                  <h3>{{ String.fromCharCode(index + 65) + '. ' + education_history.education_type }}</h3>
                </div>

                <div class="mb-3">
                  <base-label
                    :label="education_history.education_type === 'Universitas' ? 'Nama Universitas' : 'Nama Sekolah'"
                    :value="education_history.education_name"
                  />
                </div>
                <div class="mb-3">
                  <base-label
                    label="Kota"
                    :value="education_history.city"
                  />
                </div>
                <div class="mb-3">
                  <base-label
                    label="Tahun Kelulusan"
                    :value="String(education_history.graduation_year)"
                  />
                </div>
                <div
                  class="mb-3"
                  v-if="education_history.major"
                >
                  <base-label
                    label="Tahun Kelulusan"
                    :value="education_history.major"
                  />
                </div>
                <div
                  class="mb-3"
                  v-if="education_history.paid_by"
                >
                  <base-label
                    label="Dibiayai Oleh"
                    :value="education_history.paid_by"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div v-if="educationHistoryQuestions">
              <question-label
                v-for="(question, index) in educationHistoryQuestions"
                :key="'fq-' + question.question_code"
                :class="{ 'mb-40px': index < (educationHistoryQuestions.length - 1), 'mb-3': index === (educationHistoryQuestions.length - 1) }"
                :question="question.question"
                :answer="question.answer ? question.answer : '-'"
              />
            </div>
          </template>
          <div v-else>
            Tidak ada riwayat pendidikan
          </div>
        </div>
      </div>

      <!-- KURSUS PELATIHAN -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Kursus/Pelatihan</h3>
          </div>
        </div>

        <div class="card-body">
          <div
            class="row"
            v-if="flk.courses.length > 0"
          >
            <div
              class="col-lg-6"
              v-for="(course, index) in flk.courses"
              :key="course.course_name + index"
            >
              <div class="mb-3">
                <h3>{{ String.fromCharCode(index + 65) + '. ' + course.course_name }}</h3>
              </div>

              <div class="row">
                <div
                  class="col-xl-5"
                  v-if="course.medias.length > 0"
                >
                  <el-carousel
                    height="300px"
                    indicator-position="outside"
                  >
                    <el-carousel-item
                      v-for="media in course.medias"
                      :key="media.filename"
                    >
                      <pdf-thumbnail
                        v-if="getUrlExtension(media.url) === 'pdf'"
                        :fileUrl="media.url"
                        :width="400"
                        :height="800"
                      />
                      <img-thumbnail
                        v-else-if="media.type === 'image'"
                        :imgUrl="media.url"
                        altName="Course Image"
                      />
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="col-xl-7">
                  <div class="mb-3">
                    <base-label
                      label="Lama Kursus"
                      :value="course.course_periode + ' Bulan'"
                    />
                  </div>
                  <div class="mb-3">
                    <base-label
                      label="Mulai"
                      :value="dateFormat(course.start_date, 'dd mmmm yyyy')"
                    />
                  </div>
                  <div class="mb-3">
                    <base-label
                      label="Selesai"
                      :value="dateFormat(course.end_date, 'dd mmmm yyyy')"
                    />
                  </div>
                  <div class="mb-3">
                    <base-label
                      label="Penyelenggara"
                      :value="course.organizer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada kursus/pelatihan
          </div>
        </div>
      </div>

      <!-- PRESTASI -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Prestasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div
            class="row"
            v-if="flk.achievements.length > 0"
          >
            <div
              class="col-lg-4"
              v-for="(achievement, index) in flk.achievements"
              :key="achievement.achievement_name + index"
            >
              <div>
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + achievement.achievement_name }}</h3>
              </div>
              <div class="ml-4">
                <p>Tahun {{ achievement.year }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada prestasi
          </div>
        </div>
      </div>

      <!-- BAHASA DIKUASAI -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Bahasa Yang Dikuasai</h3>
          </div>
        </div>
        <div class="card-body">
          <div
            class="row"
            v-if="flk.languages.length > 0"
          >
            <div
              class="col-lg-4"
              v-for="(language, index) in flk.languages"
              :key="language.language_name + index"
            >
              <div>
                <h3 class="mb-0">{{ String.fromCharCode(index + 65) + '. ' + language.language_name }}</h3>
              </div>
              <div class="ml-4">
                <p>{{ language.is_active === true ? 'Aktif' : 'Pasif' }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada bahasa yang dikuasai
          </div>
        </div>
      </div>

      <!-- RIWAYAT PEKERJAAN -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Riwayat Pekerjaan</h3>
          </div>
        </div>

        <div class="card-body">
          <template v-if="jobHistories.length > 0">
            <div class="row">
              <div
                class="col-lg-6"
                v-for="(jobHistory, index) in jobHistories"
                :key="jobHistory.company_name + index"
              >
                <div class="mb-3">
                  <h3>{{ String.fromCharCode(index + 65) + '. ' + jobHistory.company_name }}</h3>
                </div>

                <div class="row">
                  <div
                    class="col-xl-5"
                    v-if="jobHistory.medias.length > 0"
                  >
                    <el-carousel
                      height="250px"
                      indicator-position="outside"
                    >
                      <el-carousel-item
                        v-for="media in jobHistory.medias"
                        :key="media.filename"
                      >
                        <pdf-thumbnail
                          v-if="getUrlExtension(media.url) === 'pdf'"
                          :fileUrl="media.url"
                          :width="400"
                          :height="800"
                        />
                        <img-thumbnail
                          v-else-if="media.type === 'image'"
                          :imgUrl="media.url"
                          altName="Job History Image"
                        />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <div class="col-xl-7">
                    <div class="mb-3">
                      <base-label
                        label="Alamat"
                        :value="jobHistory.address"
                      />
                    </div>
                    <div class="mb-3">
                      <base-label
                        label="Masa Kerja"
                        :value="jobHistory.start_year + ' - ' + jobHistory.end_year"
                      />
                    </div>
                    <div class="mb-3">
                      <base-label
                        label="Gaji Terakhir"
                        :value="formatRupiah(jobHistory.last_salary, 'Rp ')"
                      />
                    </div>
                    <div class="mb-3">
                      <base-label
                        label="Posisi Terakhir"
                        :value="jobHistory.position"
                      />
                    </div>
                    <div class="mb-3">
                      <base-label
                        label="Alasan Keluar"
                        :value="jobHistory.reason_of_resigning"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div v-if="jobHistoryQuestions">
              <question-label
                v-for="(question, index) in jobHistoryQuestions"
                :key="'fq-' + question.question_code"
                :class="{ 'mb-40px': index < (jobHistoryQuestions.length - 1), 'mb-3': index === (jobHistoryQuestions.length - 1) }"
                :question="question.question"
                :answer="question.answer ? question.answer : '-'"
              />
            </div>
          </template>
          <div v-else>
            Tidak ada riwayat pekerjaan
          </div>
        </div>
      </div>

      <!-- RIWAYAT ORGANISASI -->
      <div class="card card-df mt-3">
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Riwayat Organisasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div
            class="row"
            v-if="flk.organizations.length > 0"
          >
            <div
              class="col-lg-4"
              v-for="(organization, index) in flk.organizations"
              :key="organization.organization_name + index"
            >
              <div>
                <h3 class="mb-3">{{ String.fromCharCode(index + 65) + '. ' + organization.organization_name }}</h3>
              </div>
              <div class="mb-3">
                <base-label
                  label="Jabatan"
                  :value="organization.position"
                />
              </div>
              <div class="mb-3">
                <base-label
                  label="Keterangan"
                  :value="organization.note"
                />
              </div>
            </div>
          </div>
          <div v-else>
            Tidak ada riwayat organisasi
          </div>
        </div>
      </div>

      <!-- Minat & Konsep Pribadi -->
      <div
        class="card card-df mt-3"
        v-if="interestAndTalentQuestions.length > 0"
      >
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Minat & Konsep Pribadi</h3>
          </div>
        </div>
        <div class="card-body">
          <question-label
            v-for="(question, index) in interestAndTalentQuestions"
            :key="'fq-' + question.question_code"
            :class="{ 'mb-40px': index < (interestAndTalentQuestions.length - 1), 'mb-3': index === (interestAndTalentQuestions.length - 1) }"
            :question="question.question"
            :answer="question.answer ? question.answer : '-'"
          />
        </div>
      </div>

      <!-- Minat & Konsep Pribadi -->
      <div
        class="card card-df mt-3"
        v-if="socialActivityQuestions.length > 0"
      >
        <div class="card-header bg-transparent">
          <div class="d-flex">
            <h3 class="mb-0">Aktifitas Sosial dan Kegiatan-Kegiatan Lain</h3>
          </div>
        </div>
        <div class="card-body">
          <question-label
            v-for="(question, index) in socialActivityQuestions"
            :key="'fq-' + question.question_code"
            :class="{ 'mb-40px': index < (socialActivityQuestions.length - 1), 'mb-3': index === (socialActivityQuestions.length - 1) }"
            :question="question.question"
            :answer="question.answer ? question.answer : '-'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import useSwal from "@/composables/useSwal";
import useAxios from "@/composables/useAxios";
import usePageMeta from "@/composables/usePageMeta";
import useDateFormat from '@/composables/useDateFormat'
import { firstFamilyType, secondFamilyType, dataInputTypeName } from '@/composables/useFlk'
import { sortArrayObject } from '@/composables/useStringFormat'
import { useMeta } from "vue-meta";
import { formatRupiah, getUrlExtension } from '@/composables/useStringFormat'

import PdfThumbnail from '@/components/PdfThumbnail.vue'
import ImgThumbnail from '@/components/ImgThumbnail.vue'
import QuestionLabel from '@/components/Flk/QuestionLabel.vue'

import flkService from '@/services/Flk'

export default defineComponent({
  components: {
    PdfThumbnail,
    ImgThumbnail,
    QuestionLabel
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const Swal = useSwal()
    const dateFormat = useDateFormat()

    useMeta(usePageMeta(route).pageMeta)

    const paramId = computed(() => route.params.id)

    const state = reactive({
      flk: {},
      review_loading: false
    })

    // PAGE ACTIONS
    const goToEditPage = () => {
      router.push({
        name: 'Ubah Flk',
        params: {
          id: paramId.value
        }
      })
    }

    // Export Pdf
    const exportPdfPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.exportFlk(id, payload)
    }, {
      withIndicator: true
    })
    const exportPdf = async () => {
      await exportPdfPromised.createPromise(state.flk.id)
      if (exportPdfPromised.state.response) {
        const url = window.URL.createObjectURL(new Blob([exportPdfPromised.state.response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `FLK - ${state.flk.name} - ${state.flk.job_position.job_vacancy_title}.pdf`);
        document.body.appendChild(link);
        link.click();
      } else if (exportPdfPromised.state.error) {
        Swal.fire({
          icon: 'error',
          title: 'Gagal men-ekspor flk',
          text: exportPdfPromised.state.errorMessage,
        })
      }
    }

    // Review Unreview Flk
    const reviewFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.reviewFlk(id, payload)
    }, {
      withIndicator: true
    })

    const unreviewFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.unreviewFlk(id, payload)
    }, {
      withIndicator: true
    })

    const reviewUnreviewFlk = async () => {
      let success = false
      if (!state.flk.is_reviewed) {
        state.review_loading = true
        await reviewFlkPromised.createPromise(state.flk.id)
        state.review_loading = false

        if (reviewFlkPromised.state.response) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'success',
            title: 'Status flk berhasil diubah menjadi ditinjau',
          })
          success = true
        } else if (reviewFlkPromised.state.error) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'error',
            title: 'Status flk gagal diubah menjadi ditinjau',
          })
        }
      } else {
        state.review_loading = true
        await unreviewFlkPromised.createPromise(state.flk.id)
        state.review_loading = false

        if (unreviewFlkPromised.state.response) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'success',
            title: 'Status flk berhasil diubah menjadi tidak ditinjau',
          })
          success = true
        } else if (unreviewFlkPromised.state.error) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            icon: 'error',
            title: 'Status flk gagal diubah menjadi tidak ditinjau',
          })
        }
      }

      return success
    }

    // Biodata
    const applicantsPhoto = () => {
      if (!state.flk.id) {
        return ''
      }

      const applicantPhoto = state.flk.medias.filter((media) => {
        return media.name === 'profile-photo'
      })

      if (!applicantPhoto.length) {
        return ''
      }

      return applicantPhoto[0].url
    }

    const liveWithQuestion = computed(() => {
      let question = state.flk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.liveWithInput
      })

      if (question.length <= 0) {
        return null
      }

      switch (state.flk.live_with) {
        case 'Lainnya':
          question = question.filter(q => {
            return q.question_code === 'lw-01'
          })
          break;

        case 'Kost':
          question = question.filter(q => {
            return q.question_code === 'lw-02'
          })
          break;

        case 'Kontrak':
          question = question.filter(q => {
            return q.question_code === 'lw-03'
          })
          break;
      }

      return question.length > 0 ? question[0] : null
    })

    const maritalStatusQuestion = computed(() => {
      let question = state.flk.another_questions.filter(q => {
        return q.question_location === dataInputTypeName.maritalStatusInput
      })

      if (question.length <= 0) {
        return null
      }

      switch (state.flk.marital_status) {
        case 'Menikah':
          question = question.filter(q => {
            return q.question_code === 'ms-01'
          })
          break;

        case 'Bertunangan':
          question = question.filter(q => {
            return q.question_code === 'ms-02'
          })
          break;

        case 'Janda':
        case 'Duda':
          question = question.filter(q => {
            return q.question_code === 'ms-03'
          })
          break;
      }

      return question.length > 0 ? question[0] : null
    })

    // Family
    // Family By Type
    const firstFamily = computed(() => {
      return {
        type: firstFamilyType,
        members: state.flk.families.filter(family => family.family_type === firstFamilyType)
      }
    })

    const secondFamily = computed(() => {
      return {
        type: secondFamilyType,
        members: state.flk.families.filter(family => family.family_type === secondFamilyType)
      }
    })

    // Another Question Family
    const familyQuestions = computed(() => {
      const questions = state.flk.another_questions.filter(aq => {
        return aq.question_location === dataInputTypeName.familyDataInput
      })

      return sortArrayObject(questions, 'question_code')
    })

    // Education History
    const educationHistories = computed(() => {
      if (!state.flk.education_histories) {
        return []
      }

      return state.flk.education_histories.sort((a, b) => (a.graduation_year < b.graduation_year) ? 1 : ((b.graduation_year < a.graduation_year) ? -1 : 0))
    })

    // Another Question Education History
    const educationHistoryQuestions = computed(() => {
      const questions = state.flk.another_questions.filter(aq => {
        return aq.question_location === 'education_history_input'
      })

      return sortArrayObject(questions, 'question_code')
    })

    // Job History
    const jobHistories = computed(() => {
      if (!state.flk.job_histories) {
        return []
      }

      return state.flk.job_histories.sort((a, b) => (a.end_year < b.end_year) ? 1 : ((b.end_year < a.end_year) ? -1 : 0))
    })

    const jobHistoryQuestions = computed(() => {
      const questions = state.flk.another_questions.filter(aq => {
        return aq.question_location === 'job_history_input'
      })

      return sortArrayObject(questions, 'question_code')
    })

    // Interest And Talent
    const interestAndTalentQuestions = computed(() => {
      const questions = state.flk.another_questions.filter(aq => {
        return aq.question_location === 'interest_and_talent_input'
      })

      return sortArrayObject(questions, 'question_code')
    })

    // Social Activity
    const socialActivityQuestions = computed(() => {
      const questions = state.flk.another_questions.filter(aq => {
        return aq.question_location === 'social_activity_input'
      })

      return sortArrayObject(questions, 'question_code')
    })

    // Get Flk
    const getFlkPromised = useAxios((id, payload = {}) => {
      return flkService.superadmin.getFlk(id, payload)
    })
    const getFlk = async () => {
      await getFlkPromised.createPromise(paramId.value)

      if (getFlkPromised.state.response) {
        const flk = getFlkPromised.state.data
        flk.socmeds.map(socmed => {
          return {
            ...socmed,
            name: String(socmed.name)
          }
        })
        state.flk = flk
      } else if (getFlkPromised.state.error) {
        await new Promise((resolve, reject) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed to get job vacancy',
            text: getFlkPromised.state.errorMessage,
            willClose: () => {
              router.push({
                name: 'Kelola Flk'
              })
            },
          }).then(result => {
            resolve(result)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }

    onMounted(() => {
      getFlk()
    })

    return {
      ...toRefs(state),

      goToEditPage,
      exportPdf,
      reviewUnreviewFlk,

      formatRupiah,
      getUrlExtension,
      dateFormat,

      // Biodata
      applicantsPhoto,
      liveWithQuestion,
      maritalStatusQuestion,

      // Family
      firstFamily,
      secondFamily,
      familyQuestions,

      // Education History
      educationHistories,
      educationHistoryQuestions,

      // Job History
      jobHistoryQuestions,
      jobHistories,

      // Interest And Talent
      interestAndTalentQuestions,

      // Social Activity
      socialActivityQuestions,
    }
  }
})
</script>

<style lang="scss">
.card-df {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.applicant {
  &-photo {
    width: 300px;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.btn.btn-edit-flk {
  font-size: 16px;
  padding: 8px 20px;
  color: #fff;
  background: #5e72e4;
  border-radius: 40px;
}
.btn.btn-export-flk {
  padding: 8px 20px;
  background: #ffffff;
  border: 2px solid #5e72e4;
  box-sizing: border-box;
  border-radius: 40px;
  color: #5e72e4;
  font-size: 16px;
}
.label-reviewed {
  position: relative;
  color: #fff;
}
</style>